import { Injectable } from '@angular/core';
import { DownloadFormattedFileService } from './download-formatted-file.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '../../core.state';
import { Store } from '@ngrx/store';
import { DownloadFormattedFileActions } from './download-formatted-file.actions';
import {
    catchError,
    mergeMap,
    switchMap,
    withLatestFrom
} from 'rxjs/operators';
import {
    instanceIdSelector,
    scanIdSelector,
    scanLongIdSelector,
    selectedDateRangeSelector
} from '../general/general.selectors';
import { NotificationService } from '../../notifications/notification.service';

@Injectable()
export class DownloadFormattedFileEffects {
    readonly downloadXls = createEffect(() =>
        this.actions$.pipe(
            ofType(DownloadFormattedFileActions.download),
            withLatestFrom(
                this.store.select(scanLongIdSelector),
                this.store.select(instanceIdSelector),
                this.store.select(selectedDateRangeSelector),
                this.store.select(scanIdSelector)
            ),
            mergeMap(
                ([
                    { id, queryParams, format, configuration },
                    scanLongId,
                    instanceId,
                    dateRange,
                    scanId
                ]) =>
                    this.service
                        .downloadFormattedFile(
                            id,
                            queryParams,
                            scanLongId,
                            scanId,
                            instanceId,
                            dateRange,
                            format,
                            false
                        )
                        .pipe(
                            switchMap(() => {
                                return [
                                    DownloadFormattedFileActions.downloadSuccess(
                                        { id, format, configuration }
                                    )
                                ];
                            }),
                            catchError(() => {
                                this.notifications.error(
                                    'something went wrong while trying to download the xls'
                                );
                                return [
                                    DownloadFormattedFileActions.downloadFail({
                                        id,
                                        format,
                                        configuration
                                    })
                                ];
                            })
                        )
            )
        )
    );

    readonly downloadXlsViaEmail = createEffect(() =>
        this.actions$.pipe(
            ofType(DownloadFormattedFileActions.downloadViaEmail),
            withLatestFrom(
                this.store.select(scanLongIdSelector),
                this.store.select(instanceIdSelector),
                this.store.select(selectedDateRangeSelector),
                this.store.select(scanIdSelector)
            ),
            switchMap(
                ([
                    { id, queryParams, format, configuration },
                    scanLongId,
                    instanceId,
                    dateRange,
                    scanId
                ]) => {
                    return this.service
                        .downloadFormattedFile(
                            id,
                            queryParams,
                            scanLongId,
                            scanId,
                            instanceId,
                            dateRange,
                            format,
                            true
                        )
                        .pipe(
                            switchMap(() => {
                                this.notifications.info(
                                    'Your report is being processed and will be emailed to you shortly'
                                );
                                return [
                                    DownloadFormattedFileActions.downloadViaEmailSuccess(
                                        { id, format, configuration }
                                    )
                                ];
                            }),
                            catchError(() => {
                                return [
                                    DownloadFormattedFileActions.downloadViaEmailError(
                                        { id, format, configuration }
                                    )
                                ];
                            })
                        );
                }
            )
        )
    );

    constructor(
        private actions$: Actions,
        private service: DownloadFormattedFileService,
        private store: Store<AppState>,
        private notifications: NotificationService
    ) {}
}
