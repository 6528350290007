import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import { AppState } from '../../core/core.state';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { ViewType } from '../../core/state/general/general.models';
import { GeneralActions } from '../../core/state/general/general.actions';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {
    allCloudsDashboardAvailableSelector,
    allCloudsDashboardUnavailableSelector,
    allCloudsSelector,
    dashboardsAvailableSelector,
    dashboardSelector,
    dashboardsSelector,
    dashboardsUnavailableSelector,
    initialLoadingSelector,
    instanceIdSelector,
    instanceLoadingSelector,
    instanceSelector,
    instancesSelector,
    isEmptyView,
    scanLoadingSelector,
    scanSelector,
    serviceIdSelector,
    userPortalConfigurationSelector
} from '../../core/state/general/general.selectors';
import { first, map, takeUntil } from 'rxjs/operators';
import { getServicePath } from '../../utils/widget-state-creator';
import {
    qcAuthActions,
    QCAuthService,
    selectCurrentUser,
    selectEmulatedUser,
    selectOnEmulation,
    selectUser,
    User
} from '../../core/auth-lib';
import { Notification } from '../../core/state/notifications/notifications.models';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DashboardDialogComponent } from '../dashboard-dialog/dashboard-dialog.component';

@Component({
    selector: 'qcbi-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
    unsubscribe = new Subject<any>();
    executiveDashboard;
    instance: Observable<any>;
    view = ViewType.Executive;
    emulatedUser$: Observable<any>;
    user$: Observable<any>;
    isEmulating$: Observable<boolean>;

    onAllClouds$: Observable<boolean>;
    dashboards$: Observable<any[]>;
    allCloudsDashboards$: Observable<any[]>;
    layoutDashboards$: Observable<any[]>;
    dashboardsUnavailable$: Observable<any[]>;
    allCloudsUnavailableDashboards$: Observable<any[]>;
    layoutUnavailableDashboards$: Observable<any[]>;

    serviceId: Observable<number>;
    instanceId: Observable<number>;
    dashboard: Observable<any>;
    scan: Observable<any>;
    userInfo;
    emulatedUserInfo;
    isEmulatingInfo;
    initialLoading: Observable<boolean>;
    instanceLoading: Observable<boolean>;
    scanLoading: Observable<boolean>;
    isEmptyView: Observable<boolean>;
    user: Observable<User>;
    authUser: Observable<User>;
    isSso = false;
    starredInstance = false;
    starredInstances;
    onEmulation = false;

    constructor(
        public router: Router,
        private store: Store<AppState>,
        private authService: QCAuthService,
        private dialog: MatDialog,
        private ref: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.store
            .select(dashboardsSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                // TODO: search a solution for snow executive
                const d = res.find(
                    (dashboard) => dashboard.url === 'executive'
                );
                if (d) {
                    this.executiveDashboard = d;
                }
            });
        this.store
            .select(selectOnEmulation)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.onEmulation = res;
            });
        this.instance = this.store.select(instanceSelector);
        this.emulatedUser$ = this.store.select(selectEmulatedUser);
        this.user$ = this.store.select(selectUser);
        this.isEmulating$ = this.store.select(selectOnEmulation);

        this.serviceId = this.store.select(serviceIdSelector);
        this.instanceId = this.store.select(instanceIdSelector);
        this.dashboard = this.store.select(dashboardSelector);
        this.scan = this.store.select(scanSelector);
        this.initialLoading = this.store.select(initialLoadingSelector);
        this.instanceLoading = this.store.select(instanceLoadingSelector);
        this.scanLoading = this.store.select(scanLoadingSelector);
        this.onAllClouds$ = this.store.select(allCloudsSelector);

        this.dashboards$ = this.store.select(dashboardsAvailableSelector);
        this.dashboardsUnavailable$ = this.store.select(
            dashboardsUnavailableSelector
        );
        this.allCloudsDashboards$ = this.store.select(
            allCloudsDashboardAvailableSelector
        );
        this.allCloudsUnavailableDashboards$ = this.store.select(
            allCloudsDashboardUnavailableSelector
        );
        this.layoutDashboards$ = combineLatest([
            this.dashboards$,
            this.allCloudsDashboards$,
            this.onAllClouds$
        ]).pipe(
            map(([dashboards, allCloudsDashboards, onAllClouds]) => {
                this.ref.detectChanges();
                return onAllClouds ? allCloudsDashboards : dashboards;
            })
        );

        this.layoutUnavailableDashboards$ = combineLatest([
            this.dashboardsUnavailable$,
            this.allCloudsUnavailableDashboards$,
            this.onAllClouds$
        ]).pipe(
            map(([dashboards, allCloudsDashboards, onAllClouds]) => {
                this.ref.detectChanges();
                return onAllClouds ? allCloudsDashboards : dashboards;
            })
        );

        this.user = this.store.select(selectCurrentUser);
        this.authUser = this.store.select(selectUser);
        this.isEmptyView = this.store.select(isEmptyView);
        this.loadUserInfo();

        this.store
            .select(userPortalConfigurationSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((userPortalConfiguration) => {
                this.starredInstances =
                    userPortalConfiguration.starredInstances;
                this.checkStarred();
            });
        this.instance.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.checkStarred();
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    async checkStarred() {
        const instance = await this.instance.pipe(first()).toPromise();
        if (instance === undefined || instance === null) return;
        const services = ['serviceNow', 'salesForce', 'office365'];
        this.starredInstance =
            this.starredInstances[services[+instance.serviceId - 1]].find(
                (i) => i.id === instance.id
            ) !== undefined;
    }

    async loadUserInfo() {
        this.userInfo = this.user$
            .pipe(first())
            .toPromise()
            .then((data) => {
                return data;
            });
        this.emulatedUserInfo = this.emulatedUser$
            .pipe(first())
            .toPromise()
            .then((data) => {
                return data;
            });
        this.isEmulatingInfo = this.isEmulating$
            .pipe(first())
            .toPromise()
            .then((data) => {
                return data;
            });
        const workspace = this.authService.getToken('sso_workspace');
        if (workspace !== null && workspace !== '') {
            this.isSso = true;
        }
    }

    async handleViewChange(event) {
        // TODO: review this navigation
        // tslint:disable-next-line: deprecation
        const state = await combineLatest(
            this.serviceId.pipe(first()),
            this.instanceId.pipe(first()),
            this.dashboards$.pipe(first()),
            this.onAllClouds$.pipe(first()),
            this.dashboardsUnavailable$.pipe(first()),

            (
                // tslint:disable-next-line:no-shadowed-variable
                serviceId,
                // tslint:disable-next-line: no-shadowed-variable
                instanceId,
                dashboards,
                // tslint:disable-next-line: no-shadowed-variable
                onAllClouds,
                dashboardsUnavailable
            ) => ({
                serviceId,
                instanceId,
                selectedDashboard: this.onEmulation
                    ? dashboards
                          .concat(dashboardsUnavailable)
                          .find((dashboard) => dashboard.id === event.value)
                    : dashboards.find(
                          (dashboard) => dashboard.id === event.value
                      ),
                onAllClouds
            })
        ).toPromise();

        const { serviceId, instanceId, selectedDashboard, onAllClouds } = state;
        if (onAllClouds) {
            this.router.navigate(['/all-clouds', event.value]);
            return;
        }
        const servicePath = getServicePath(serviceId);
        this.store.dispatch(
            GeneralActions.updateSettings({
                settings: {
                    dashboardId: event.value,
                    instanceId,
                    serviceId
                }
            })
        );
        this.store.dispatch(
            GeneralActions.setDashboard({
                dashboard: selectedDashboard
            })
        );
        if (selectedDashboard['is-custom-bi']) {
            this.router.navigate([
                '/' + servicePath,
                instanceId,
                selectedDashboard.url
            ]);
        } else {
            this.router.navigate([
                '/' + servicePath,
                instanceId,
                'dashboard',
                event.value
            ]);
        }
    }

    handleEndEmulation() {
        this.store.dispatch(qcAuthActions.endEmulation());
    }

    handleLogout() {
        this.store.dispatch(qcAuthActions.logout());
    }

    goToAdmin() {
        window.open(environment.adminUrl);
    }

    goToChangePassword() {
        const url =
            environment.loginUrl +
            'change/auth?returnTo=' +
            encodeURI(window.location.href);
        window.open(url, '_self');
    }

    async toggleStarredInstance() {
        const instanceResponse = await this.instance.pipe(first()).toPromise();
        const { id, serviceId, ...attributes } = instanceResponse;
        attributes['service-id'] = serviceId;
        const instance = {
            id,
            serviceId,
            attributes
        };
        const services = ['serviceNow', 'salesForce', 'office365'];
        const newStarredInstances = { ...this.starredInstances };
        if (!this.starredInstance) {
            newStarredInstances[services[+instance.serviceId - 1]] = [
                ...newStarredInstances[services[+instance.serviceId - 1]],
                instance
            ];
        } else {
            newStarredInstances[services[+instance.serviceId - 1]] =
                newStarredInstances[services[+instance.serviceId - 1]].filter(
                    (i) => i.id !== instance.id
                );
        }
        this.store.dispatch(
            GeneralActions.setUserConfigurationItem({
                key: 'starredInstances',
                value: newStarredInstances
            })
        );
    }

    notificationClickedHandler(event: Notification) {
        this.store
            .select(instancesSelector)
            .pipe(first())
            .subscribe((res) => {
                const k = Object.keys(res);
                let i, instance;
                for (i = 0; i < k.length; ++i) {
                    instance = res[k[i]].find(
                        (inst) => +inst.id === +event.instanceId
                    );
                    if (instance) {
                        break;
                    }
                }
                this.router
                    .navigate([
                        '/' + k[i].toLowerCase(),
                        event.instanceId,
                        'executive'
                    ])
                    .then(() => {
                        this.store.dispatch(
                            GeneralActions.instanceLoad({
                                serviceId: instance.serviceId,
                                instanceId: event.instanceId,
                                dashboardId: this.executiveDashboard.id
                            })
                        );
                        this.store.dispatch(
                            GeneralActions.setDashboard({
                                dashboard: this.executiveDashboard
                            })
                        );
                    });
            });
    }

    openDashboardInfo(dashboard) {
        this.dialog.open(DashboardDialogComponent, { data: dashboard });
    }
}
