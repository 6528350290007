import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { Observable, of } from 'rxjs';

export type ConfirmDialogData = {
    title: string;
    content: string;
    actionText?: string;
    loading?: Observable<boolean>;
    actionCallback?: (
        dialogRef?: MatDialogRef<NewConfirmDialogComponent>
    ) => void;
};

@Component({
    selector: 'qcbi-new-confirm-dialog',
    templateUrl: 'new-confirm-dialog.component.html',
    styleUrls: ['new-confirm-dialog.component.scss']
})
export class NewConfirmDialogComponent implements OnInit {
    loading$: Observable<boolean>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
        private dialogRef: MatDialogRef<NewConfirmDialogComponent>
    ) {}

    ngOnInit() {
        if (this.data.loading) this.loading$ = this.data.loading;
        else this.loading$ = of(false);
    }

    closeDialog() {
        this.dialogRef.close(false);
    }

    accept() {
        if (this.data.actionCallback) {
            this.data.actionCallback(this.dialogRef);
        } else {
            this.dialogRef.close(true);
        }
    }
}
