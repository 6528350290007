import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
    map,
    scan,
    shareReplay,
    switchMap,
    takeUntil,
    tap
} from 'rxjs/operators';
import { AssignationResult } from './assignation.model';
import { stateSelectors } from '../../core/state/widgets/large/debt-manager-new/dm-new.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';
import { instanceIdSelector } from '../../core/state/general/general.selectors';
import { TeamService } from '../../core/services/teams.service';
import { SelectableTeam } from '../../models/selectable-team.model';
import { bulkActions } from '../../core/state/widgets/large/debt-manager-new/bulk.actions';

type selectedItemTeam = {
    id: number;
    name: string;
};

type selectedItem = {
    'Element Id': number;
    Teams: selectedItemTeam[];
};

export type AssignTeamDialogData = {
    allSelected: boolean;
    selectedItems: selectedItem[];
};

@Component({
    selector: 'qcbi-assign-team-dialog',
    templateUrl: 'assign-team-dialog.component.html'
})
export class AssignTeamDialogComponent implements OnInit, OnDestroy {
    toggleTeamToAssign$ = new BehaviorSubject<{ teamId: number }>(null);
    toggleTeamToUnassign$ = new BehaviorSubject<{ teamId: number }>(null);
    availableTeams$: Observable<SelectableTeam[]>;
    selectedTeams$: Observable<SelectableTeam[]>;
    teamsToUnassign$: Observable<object>;
    teamsToAssign$: Observable<object>;
    teamsToAssignCount$: Observable<number>;
    assignButtonDisabled$: Observable<boolean>;
    savingTeams = false;

    loadingSelected = true;
    result: AssignationResult = {
        unassignTeamsIds: [],
        assignTeamsIds: []
    };

    private unsubscribe$ = new Subject();

    constructor(
        private teamService: TeamService,
        private store: Store<AppState>,
        public dialogRef: MatDialogRef<
            AssignTeamDialogComponent,
            AssignationResult
        >,
        @Inject(MAT_DIALOG_DATA) public data: AssignTeamDialogData
    ) {}

    ngOnInit() {
        const dataSources$ = this.getDataSources();
        this.availableTeams$ = dataSources$.pipe(
            map(([teams, dict]) => {
                return teams.filter((team) => !dict.hasOwnProperty(team.id));
            }),
            switchMap((teams) => {
                return this.teamsToAssign$.pipe(
                    map((res) => {
                        return teams.map((team) => {
                            return {
                                ...team,
                                selected: res.hasOwnProperty(team.id)
                            };
                        });
                    })
                );
            }),
            shareReplay(1)
        );
        this.selectedTeams$ = dataSources$.pipe(
            map(([teams, dict]) => {
                return teams.filter((team) => dict.hasOwnProperty(team.id));
            }),
            switchMap((teams) => {
                return this.teamsToUnassign$.pipe(
                    map((res) => {
                        return teams.map((team) => {
                            return {
                                ...team,
                                selected: !res.hasOwnProperty(team.id)
                            };
                        });
                    })
                );
            }),
            tap(() => {
                this.loadingSelected = false;
            }),
            shareReplay(1)
        );
        this.teamsToUnassign$ = this.toggleTeamToUnassign$.pipe(
            scan((acc, next) => {
                if (!next) return acc;
                if (acc.hasOwnProperty(next.teamId)) {
                    const res = { ...acc };
                    delete res[next.teamId];
                    return res;
                } else {
                    return { ...acc, [next.teamId]: true };
                }
            }, {})
        );
        this.teamsToAssign$ = this.toggleTeamToAssign$.pipe(
            scan((acc, next) => {
                if (!next) return acc;
                if (acc.hasOwnProperty(next.teamId)) {
                    const res = { ...acc };
                    delete res[next.teamId];
                    return res;
                } else {
                    return { ...acc, [next.teamId]: true };
                }
            }, {})
        );
        this.teamsToAssignCount$ = this.teamsToAssign$.pipe(
            map((teamsToAssign) => {
                return Object.keys(teamsToAssign).length;
            })
        );
        const result = combineLatest([
            this.teamsToAssign$,
            this.teamsToUnassign$
        ]).pipe(takeUntil(this.unsubscribe$));
        this.assignButtonDisabled$ = result.pipe(
            map(([teamsToAssign, teamsToUnAssign]) => {
                return (
                    Object.keys(teamsToAssign).length === 0 &&
                    Object.keys(teamsToUnAssign).length === 0
                );
            })
        );

        result.subscribe(([assignIdsDictionary, unassignIdsDictionary]) => {
            this.result.assignTeamsIds = Object.keys(assignIdsDictionary);
            this.result.unassignTeamsIds = Object.keys(unassignIdsDictionary);
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    close() {
        this.dialogRef.close();
    }

    submitChanges() {
        this.store.dispatch(
            bulkActions.bulkAssignTeam({
                allSelected: this.data.allSelected,
                ids: this.data.selectedItems.map((e) => +e['Element Id']),
                assignTeamsIds: this.result.assignTeamsIds,
                unassignTeamsIds: this.result.unassignTeamsIds
            })
        );
        this.savingTeams = true;
    }

    toggleTeamToAssign(teamId: number) {
        this.toggleTeamToAssign$.next({ teamId });
    }

    toggleTeamToUnassign(teamId: number) {
        this.toggleTeamToUnassign$.next({ teamId });
    }

    private getDataSources() {
        const customerTeams$ = this.teamService.getCustomerTeams();
        if (this.data.allSelected) {
            const currentFilterTeams$ = combineLatest([
                this.store.select(stateSelectors.filter),
                this.store.select(instanceIdSelector)
            ]).pipe(
                switchMap(([filter, instanceId]) =>
                    this.teamService.getTeamsWithFilterDictionary(
                        instanceId,
                        filter
                    )
                )
            );
            return combineLatest([customerTeams$, currentFilterTeams$]);
        } else {
            return combineLatest([
                customerTeams$,
                of(this.getTeamsIdsDictionary())
            ]);
        }
    }

    private getTeamsIdsDictionary(): object {
        return this.data.selectedItems.reduce((result, el) => {
            for (let i = 0; i < el.Teams?.length; ++i) {
                if (!result.hasOwnProperty(el.Teams[i].id))
                    result[el.Teams[i].id] = 0;
                ++result[el.Teams[i].id];
            }
            return result;
        }, {});
    }
}
