import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
const packageJson = require('../../../../../../package.json');

@Injectable({
    providedIn: 'root'
})
export class VersionService {
    constructor(private http: HttpClient) {}

    getVersion(): Observable<string> {
        return this.http
            .get<any>(`${environment.apiDevEndPoint}api/v2/version`)
            .pipe(
                map((res) => {
                    return res.version;
                })
            );
    }

    getLocalVersion(): string {
        return packageJson.version;
    }
}
