import { Component, Input, OnInit } from '@angular/core';
import {
    DownloadFormats,
    DownloadFormattedFileActions
} from '../../../core/state/download-formatted-file/download-formatted-file.actions';
import {
    getRestFilterName,
    mapFilterPath
} from '../../../utils/widget-state-creator';
import { MAX_DOWNLOAD_LENGTH } from '../../../../utils/constants';
import {
    ConfirmDialogData,
    NewConfirmDialogComponent
} from '../../../shared/new-confirm-dialog/new-confirm-dialog.component';
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/core.state';
import { downloadInProgressSelector } from '../../../core/state/download-formatted-file/download-formatted-file.selectors';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { selectCurrentUserEmail } from '../../../core/auth-lib';

const SPECIAL_DOWNLOAD_TYPES = [
    'governance',
    'live-check-scans',
    'live-check-issues-by-scan',
    'scanned-update-sets',
    'application-scans',
    'feature-branch-scans',
    'developer-stats'
];

@Component({
    selector: 'qcbi-file-downloader',
    templateUrl: 'file-downloader.component.html',
    styles: [
        `
            .download-xls {
                line-height: 28px;
                text-decoration: underline;
                margin: 0 5px;
                font-weight: bold;
            }
        `
    ]
})
export class FileDownloaderComponent implements OnInit {
    @Input() downloadType: string;
    @Input() loading: boolean;
    @Input() configuration: string;
    @Input() downloadFilters: any;
    @Input() paginator: MatPaginator;

    downloadLoadingXls$: Observable<boolean>;
    downloadLoadingSarif$: Observable<boolean>;

    readonly MAX_TO_DOWNLOAD = 60000;
    protected readonly DownloadFormats = DownloadFormats;

    constructor(public store: Store<AppState>, public dialog: MatDialog) {}

    ngOnInit() {
        this.downloadLoadingXls$ = this.store
            .select(downloadInProgressSelector)
            .pipe(
                map((res) =>
                    res.hasOwnProperty(
                        `${this.downloadType}_${this.configuration}_${DownloadFormats.xls}`
                    )
                )
            );
        this.downloadLoadingSarif$ = this.store
            .select(downloadInProgressSelector)
            .pipe(
                map((res) =>
                    res.hasOwnProperty(
                        `${this.downloadType}_${this.configuration}_${DownloadFormats.sarif}`
                    )
                )
            );
    }

    async downloadIssues(format: DownloadFormats) {
        const filterKeys = Object.keys(this.downloadFilters);
        const filters = filterKeys.reduce((result, key) => {
            const specialDownloadTypes = new Set(SPECIAL_DOWNLOAD_TYPES);
            const isSpecialDownloadType = specialDownloadTypes.has(
                this.downloadType
            );
            const name = isSpecialDownloadType ? key : getRestFilterName(key);
            if (this.isValidFilter(name, key, this.downloadFilters))
                result.push({
                    key: isSpecialDownloadType
                        ? mapFilterPath(name)
                        : `filter[${mapFilterPath(name)}]`,
                    value: Array.isArray(this.downloadFilters[key])
                        ? this.downloadFilters[key].join(',')
                        : this.downloadFilters[key]
                });
            return result;
        }, []);

        if (
            this.paginator.length > MAX_DOWNLOAD_LENGTH &&
            format === DownloadFormats.xls &&
            this.downloadType.includes('issue')
        ) {
            this.store
                .select(selectCurrentUserEmail)
                .pipe(first())
                .subscribe((email) => {
                    this.showExportDialog(filters, format, email);
                });
            return;
        }

        this.store.dispatch(
            DownloadFormattedFileActions.download({
                id: this.downloadType,
                configuration: this.configuration,
                queryParams: filters,
                format
            })
        );
    }

    private isValidFilter(name: string, key: string, downloadFilters: any[]) {
        const value = downloadFilters[key];
        return (
            name !== 'sort' &&
            name !== 'top' &&
            value !== '' &&
            value !== undefined &&
            (!Array.isArray(value) || value.length > 0)
        );
    }

    private showExportDialog(
        filters: any,
        format: DownloadFormats,
        email: string = 'your email'
    ) {
        const data: ConfirmDialogData = {
            title: 'Export Report via Email',
            content:
                `The report has over ${MAX_DOWNLOAD_LENGTH} issues and ` +
                `will be sent to ${email} as a CSV file. ` +
                `Would you like to continue?`,
            actionText: 'Send Email',
            actionCallback: (
                dialogRef: MatDialogRef<NewConfirmDialogComponent>
            ) => {
                this.store.dispatch(
                    DownloadFormattedFileActions.downloadViaEmail({
                        id: this.downloadType,
                        configuration: this.configuration,
                        queryParams: filters,
                        format
                    })
                );
                dialogRef.close();
            }
        };
        this.dialog.open(NewConfirmDialogComponent, {
            data,
            panelClass: 'no-padding-modal'
        });
    }
}
