import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { filtersConfig } from './filters/filters.config';
import { FilterChangedEvent, FilterOptions } from './filters/filter.component';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

export type SelectableFilterChangedEvent = FilterChangedEvent & { id: string };

export type FilterOptionsDictionary = {
    [key: string]: FilterOptions[];
};

@Component({
    selector: 'qcbi-selectable-filters-component',
    templateUrl: 'selectable-filters.component.html',
    styleUrls: ['./selectable-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectableFiltersComponent implements OnInit {
    @Input() availableFilters: Observable<string[]>;
    @Input() selectedOptions: Observable<FilterOptionsDictionary>;
    @Input() filterOptions: Observable<FilterOptionsDictionary>;
    @Input() canShowStatus = true;

    @Output() filterChanged = new EventEmitter<SelectableFilterChangedEvent>();
    @Output() filtersReset = new EventEmitter();
    @Output() addFilterToDisplay = new EventEmitter<{ id: string }>();
    @Output() removeFilterFromDisplay = new EventEmitter<{ id: string }>();

    filtersList$: Observable<{ id: string; label: string; checked: boolean }[]>;
    selectedFilters$: Observable<
        { id: string; options: FilterOptions[]; values: (string | number)[] }[]
    >;

    ngOnInit() {
        this.filtersList$ = combineLatest([
            this.availableFilters,
            this.selectedOptions
        ]).pipe(
            map(([filters, selectedOptions]) => {
                return filters
                    .filter((f) => f !== 'issue_status')
                    .map((filter) => ({
                        label: filtersConfig[filter].label,
                        id: filter,
                        checked: selectedOptions[filter] !== undefined
                    }));
            })
        );

        this.selectedFilters$ = combineLatest([
            this.selectedOptions,
            this.filterOptions
        ]).pipe(
            map(([selected, options]) => {
                return Object.keys(selected).map((key: string) => {
                    return {
                        id: key,
                        options: options[key],
                        values: selected[key].map((op) => op.value)
                    };
                });
            })
        );
    }

    handleNewSelectedOptions(event: FilterChangedEvent, id: string) {
        this.filterChanged.emit({ ...event, id });
    }

    handleResetFiltersClick() {
        this.filtersReset.emit();
    }

    handleAvailableFilterChange(event: MatCheckboxChange, id: string) {
        if (event.checked) {
            this.addFilterToDisplay.emit({ id });
        } else {
            this.removeFilterFromDisplay.emit({ id });
        }
    }
}
