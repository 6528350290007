import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaselineToggleComponent } from './baseline-toggle.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
    imports: [CommonModule, MatSlideToggleModule, MatTooltipModule],
    declarations: [BaselineToggleComponent],
    exports: [BaselineToggleComponent]
})
export class BaselineToggleModule {}
