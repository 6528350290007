import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RangeSelectComponent } from './range-select.component';
import { SatDatepickerModule } from 'saturn-datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        SatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule
    ],
    exports: [RangeSelectComponent],
    declarations: [RangeSelectComponent]
})
export class RangeSelectModule {}
