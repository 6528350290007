import { createAction, props } from '@ngrx/store';

export enum DownloadFormats {
    xls = 'xls',
    sarif = 'sarif'
}

type downloadProps = {
    id: string;
    configuration: string;
    queryParams: { key: string; value: string | number }[];
    format: DownloadFormats;
};

type downloadResponseProps = {
    id: string;
    configuration: string;
    format: DownloadFormats;
};

export const DownloadFormattedFileActions = {
    download: createAction(
        '[Download formatted file] download',
        props<downloadProps>()
    ),
    downloadSuccess: createAction(
        '[Download formatted file] download success',
        props<downloadResponseProps>()
    ),
    downloadFail: createAction(
        '[Download formatted file] download fail',
        props<downloadResponseProps>()
    ),
    downloadViaEmail: createAction(
        '[Download formatted file] download via email',
        props<downloadProps>()
    ),
    downloadViaEmailSuccess: createAction(
        '[Download formatted file] download via email success',
        props<downloadResponseProps>()
    ),
    downloadViaEmailError: createAction(
        '[Download formatted file] download via email error',
        props<downloadResponseProps>()
    )
};
