import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Chart, ChartSizes } from '../../abstracts/chart.component';
import {
    ApexChart,
    ApexPlotOptions,
    ApexStroke,
    ApexAxisChartSeries,
    ApexXAxis,
    ApexGrid
} from 'ng-apexcharts';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';

@Component({
    selector: 'qcbi-bar-stacked-chart',
    template: `
        <apx-chart
            #chart
            [autoUpdateSeries]="true"
            [chart]="chartConfig"
            [dataLabels]="dataLabels"
            [legend]="legend"
            [noData]="noData"
            [plotOptions]="plotOptions"
            [responsive]="responsive"
            [series]="series"
            [states]="states"
            [stroke]="stroke"
            [theme]="theme"
            [title]="title"
            [tooltip]="tooltip"
            [xaxis]="_xaxis"
        >
        </apx-chart>
    `,
    styleUrls: ['./charts.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarStackedChartComponent extends Chart {
    sizes: ChartSizes = {
        small: {
            height: 150,
            width: '100%',
            responsive: []
        },
        medium: {
            height: 500,
            width: '100%',
            responsive: []
        },
        large: {
            height: 600,
            width: '100%',
            responsive: []
        }
    };
    _xaxis: ApexXAxis = {};
    _xSeriesTitle = '';

    chartConfig: ApexChart = {
        type: 'bar',
        stacked: true,
        toolbar: {
            show: true,
            offsetY: 15,
            offsetX: -15,
            tools: {
                pan: false
            }
        },
        fontFamily: 'Rubik, sans-serif'
    };

    plotOptions: ApexPlotOptions = {
        bar: {
            dataLabels: {
                position: 'top',
                orientation: 'vertical'
            }
        }
    };

    stroke: ApexStroke = {
        show: true,
        width: 1,
        colors: ['#fff']
    };
    grid: ApexGrid = {
        xaxis: {
            lines: {
                show: true
            }
        },
        yaxis: {
            lines: {
                show: false
            }
        },
        column: {
            colors: ['#F8F9FA', 'transparent']
        }
    };
    @Input() series: ApexAxisChartSeries;
    @Input() xaxisType: ApexXAxis['type'] = 'datetime';
    // @Input() xaxis: ApexXAxis;
    @Input()
    set xaxis(value: ApexXAxis) {
        if (!value) return;
        this._xaxis = {
            ...value,
            type: this.xaxisType,
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: false,
                minHeight: 50,
                showDuplicates: true,
                trim: true,
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM',
                    day: 'dd MMM',
                    hour: 'HH:mm dd',
                    minute: 'HH:mm'
                }
            },
            tickPlacement: 'on'
        };
    }

    constructor(
        public datepipe: DatePipe,
        public numberpipe: DecimalPipe,
        public store: Store<AppState>
    ) {
        super(datepipe, store, numberpipe);
        this.dataLabels = {
            enabled: false,
            textAnchor: 'start',
            style: this.dataLabels.style
        };
    }
}
