import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileDownloaderComponent } from './file-downloader.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonLoadingModule } from '../../../shared/button-loading/mat-button-loading.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatButtonLoadingModule,
        MatTooltipModule
    ],
    exports: [FileDownloaderComponent],
    declarations: [FileDownloaderComponent]
})
export class FileDownloaderModule {}
