import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultTableComponent } from './default.component';
import { ServerTableComponent } from './server.component';
import { ColourBubbleModule } from '../../shared/colour-bubble/colour-bubble.module';
import { ChipsetModule } from '../../shared/chipset/chipset.module';
import { TablePipe } from '../../pipes/table.pipe';
import { ResizableModule } from 'angular-resizable-element';
import { CustomTooltipDirective } from '../../shared/tables/table-tooltip/tooltip.directive';
import { TableConfigurationModule } from './table-configuration/table-configuration.module';
import { Csv2ArrayPipe } from '../../pipes/csv2array.pipe';
import { MatButtonLoadingModule } from '../../shared/button-loading/mat-button-loading.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { UserDateTimeModule } from '../../pipes/user-date-time/user-date-time.module';
import { ColoredDotModule } from '../../shared/colored-dot/colored-dot.module';
import { FileDownloaderModule } from './file-downloader/file-downloader.module';

@NgModule({
    declarations: [
        ServerTableComponent,
        DefaultTableComponent,
        TablePipe,
        Csv2ArrayPipe,
        CustomTooltipDirective
    ],
    imports: [
        MatTableModule,
        CommonModule,
        ColourBubbleModule,
        ChipsetModule,
        MatCheckboxModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatToolbarModule,
        MatSelectModule,
        MatTabsModule,
        MatExpansionModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatChipsModule,
        MatCardModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatListModule,
        MatMenuModule,
        MatIconModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatButtonToggleModule,
        MatPaginatorModule,
        MatSortModule,
        MatRippleModule,
        ResizableModule,
        TableConfigurationModule,
        MatButtonLoadingModule,
        UserDateTimeModule,
        ColoredDotModule,
        FileDownloaderModule
    ],
    exports: [
        ServerTableComponent,
        DefaultTableComponent,
        TablePipe,
        Csv2ArrayPipe
    ]
})
export class TableModule {}
