import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainFilterComponent } from './main-filter.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
    imports: [CommonModule, MatSelectModule, MatTooltipModule],
    declarations: [MainFilterComponent],
    exports: [MainFilterComponent]
})
export class MainFilterModule {}
