import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { SatDatepickerRangeValue } from 'saturn-datepicker';
import { getYYYYMMDDfromDate } from '../../../utils/general';
import { Router } from '@angular/router';
import { NotificationService } from '../../notifications/notification.service';
import { DownloadFormats } from './download-formatted-file.actions';

@Injectable({
    providedIn: 'root'
})
export class DownloadFormattedFileService {
    constructor(
        private http: HttpClient,
        private router: Router,
        private notificationsService: NotificationService
    ) {}

    downloadFormattedFile(
        id: string,
        queryParams: { key: string; value: string | number }[] = [],
        scanLongId: string | number,
        scanId: string | number,
        instanceId: string | number,
        dateRange: SatDatepickerRangeValue<Date>,
        format = DownloadFormats.xls,
        viaEmail: boolean
    ): Observable<any> {
        const config = this.getConfigFromId(id, instanceId, scanId);
        const qp = [...queryParams];
        if (config.getScan) {
            qp.push({
                key: `filter[${config.getScan}]`,
                value: scanLongId + ''
            });
        }
        if (config.date) {
            if (dateRange === null) {
                dateRange = this.getInitialDate();
            }
            qp.push({
                key: 'from',
                value: getYYYYMMDDfromDate(dateRange.begin)
            });
            qp.push({
                key: 'to',
                value: getYYYYMMDDfromDate(dateRange.end)
            });
        }
        return this.http
            .post(
                viaEmail
                    ? `${environment.apiEndPoint}exports/v1/${format}-by-email`
                    : `${environment.apiEndPoint}exports/v1/${format}`,
                {
                    path: config.path,
                    source: config.source,
                    id: config.id,
                    responseType: config.responseType,
                    async: false,
                    queryParams: qp
                },
                { responseType: 'blob' }
            )
            .pipe(
                map(data => {
                    if (!viaEmail) this.downloadFile(data, format);
                    return data;
                }),
                catchError(error => {
                    error.catch((res: any) => {
                        if (res.error && res.error.message)
                            this.notificationsService.error(res.error.message);
                        else
                            this.notificationsService.error(
                                'Something went wrong'
                            );
                    });
                    throw new Error();
                })
            );
    }

    private downloadFile(data: BlobPart, format: DownloadFormats): void {
        const MIME_TYPES: Record<DownloadFormats, string> = {
            [DownloadFormats.xls]:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            [DownloadFormats.sarif]: 'application/json'
        };
        const fileExtension = format === DownloadFormats.xls ? 'xlsx' : 'sarif';
        const mimeType = MIME_TYPES[format];

        const blob = new Blob([data], { type: mimeType });

        const anchorElement = document.createElement('a');
        anchorElement.href = URL.createObjectURL(blob);
        anchorElement.download = `${this.getFileNameFromUrl()}.${fileExtension}`;

        document.body.appendChild(anchorElement);
        anchorElement.click();
        URL.revokeObjectURL(anchorElement.href);
        document.body.removeChild(anchorElement);
    }

    private getFileNameFromUrl() {
        const urlSegments = this.router.url.split('/');
        return urlSegments.filter(s => isNaN(+s)).join('_');
    }

    private getInitialDate(): SatDatepickerRangeValue<Date> {
        const begin = new Date();
        begin.setMonth(begin.getMonth() - 1);
        const end = new Date();
        return { begin, end } as SatDatepickerRangeValue<Date>;
    }

    private getConfigFromId(
        id: string,
        instanceId: string | number | undefined | null,
        scanId: string | number | undefined | null
    ): {
        path: string;
        source: string;
        responseType: string;
        getScan: string;
        date: boolean;
        id: string | number | undefined | null;
    } {
        switch (id) {
            case 'live-check-scans':
            case 'live-check-issues-by-scan':
            case 'scanned-update-sets':
            case 'application-scans':
            case 'feature-branch-scans':
                return {
                    path: `/widgets/v1/${id}/${instanceId}`,
                    source: 'api',
                    responseType: 'biwidget',
                    getScan: undefined,
                    date: true,
                    id: instanceId
                };
            case 'developer-stats':
                return {
                    path: `/widgets/v1/${id}/${scanId}`,
                    source: 'api',
                    responseType: 'biwidget',
                    getScan: undefined,
                    date: false,
                    id: scanId
                };
            case 'catalog-items-breakdown':
                return {
                    path: `/widgets/v1/${id}/${scanId}`,
                    source: 'api',
                    responseType: 'biwidget',
                    getScan: undefined,
                    date: false,
                    id: undefined
                };
            case 'governance':
                return {
                    path: '/widgets/v1/governance-summary',
                    source: 'api',
                    responseType: 'biwidget',
                    getScan: undefined,
                    date: false,
                    id: undefined
                };
            case 'issues':
                return {
                    path: '/v2/issue',
                    source: 'services',
                    responseType: 'jsonapi',
                    getScan: 'scan',
                    date: false,
                    id: undefined
                };
            case 'open-issue':
                return {
                    path: '/v2/open-issue',
                    source: 'services',
                    responseType: 'jsonapi',
                    getScan: undefined,
                    date: false,
                    id: undefined
                };
            case 'compare-issues':
                return {
                    path: '/v2/issue',
                    source: 'services',
                    responseType: 'jsonapi',
                    getScan: undefined,
                    date: false,
                    id: undefined
                };
            case 'configuration-elements':
                return {
                    path: '/v2/auditable-element',
                    source: 'services',
                    responseType: 'jsonapi',
                    getScan: 'scan_uuid',
                    date: false,
                    id: undefined
                };
            case 'code-monitor':
                return {
                    path: '/v2/auditable-element',
                    source: 'services',
                    responseType: 'jsonapi',
                    getScan: undefined,
                    date: false,
                    id: undefined
                };
            case 'catalog-category':
            case 'duplicate-variable':
            case 'transaction':
            case 'catalog-item':
            case 'ootb-affected-by-upgrade':
                return {
                    path: `/v2/${id}`,
                    source: 'services',
                    responseType: 'jsonapi',
                    getScan: undefined,
                    date: false,
                    id: undefined
                };
            default:
                return {
                    path: `/v2/${id}`,
                    source: 'services',
                    responseType: 'jsonapi',
                    getScan: 'scan_uuid',
                    date: false,
                    id: undefined
                };
        }
    }
}
