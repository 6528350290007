import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import {
    ApexChart,
    ApexNonAxisChartSeries,
    ApexPlotOptions,
    ApexStroke
} from 'ng-apexcharts';
import { Chart } from '../../abstracts/chart.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';

@Component({
    selector: 'qcbi-pie-chart',
    template: `
        <apx-chart
            #chart
            [autoUpdateSeries]="true"
            [chart]="chartConfig"
            [colors]="colors"
            [dataLabels]="dataLabels"
            [labels]="labels"
            [legend]="legend"
            [noData]="noData"
            [plotOptions]="plotOptions"
            [responsive]="responsive"
            [series]="series"
            [states]="states"
            [stroke]="stroke"
            [subtitle]="subtitle"
            [theme]="colors ? undefined : theme"
            [title]="title"
            [tooltip]="tooltip"
        >
        </apx-chart>
    `,
    styleUrls: ['./charts.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieChartComponent extends Chart {
    @Input() series: ApexNonAxisChartSeries;
    @Input() labels: string[];

    @Output() dataSelected = new EventEmitter();

    sizes = {
        small: {
            height: 380,
            width: '100%',
            responsive: []
        },
        medium: {
            height: 500,
            width: '100%',
            responsive: []
        },
        large: {
            height: 750,
            width: '100%',
            responsive: []
        }
    };

    chartConfig: ApexChart = {
        type: 'pie',
        toolbar: {
            show: true,
            offsetY: 50,
            offsetX: -15,
            tools: {
                pan: false
            }
        },
        height: undefined,
        width: undefined,
        fontFamily: 'Rubik, sans-serif',
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: false
            }
        },
        events: {
            dataPointSelection: (event, chartContext, config) => {
                this.dataSelected.emit(config);
            }
        }
    };

    plotOptions: ApexPlotOptions = {
        pie: {
            customScale: 0.85,
            offsetX: 0,
            offsetY: 0,
            expandOnClick: true,
            dataLabels: {
                offset: 60,
                minAngleToShowLabel: 10
            }
        }
    };

    stroke: ApexStroke = {
        show: false
    };

    constructor(
        public datepipe: DatePipe,
        public numberpipe: DecimalPipe,
        public translateService: TranslateService,
        public store: Store<AppState>
    ) {
        super(datepipe, store, numberpipe, translateService);
    }
}
