import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsPageComponent } from './notifications-page.component';
import { NotificationCardComponent } from './components/notification-card.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@NgModule({
    imports: [CommonModule, MatProgressSpinnerModule],
    declarations: [NotificationsPageComponent, NotificationCardComponent],
    exports: [NotificationsPageComponent]
})
export class NotificationsPageModule {}
