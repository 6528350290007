import { Injectable } from '@angular/core';
import {
    ActionableService,
    ActionDispatchedEvent
} from '../../../../../tables/services/action.models';
import { UnwriteOffDialogComponent } from '../../../../../../shared/unwrite-off-dialog/unwrite-off-dialog.component';
import { bulkActions } from '../../../../../../core/state/widgets/large/debt-manager-new/bulk.actions';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/core.state';

@Injectable({
    providedIn: 'root'
})
export class UnwriteOffOnlyDeclineActionService implements ActionableService {
    readonly actionId = 'un-write-off-only-decline';

    constructor(private dialog: MatDialog, private store: Store<AppState>) {}

    executeAction(event: ActionDispatchedEvent): void {
        const unwoAcceptRef = this.dialog.open(UnwriteOffDialogComponent, {
            width: '600px',
            data: {
                ids: event.selectedItems.map((el) => +el['Element Id']),
                allSelected: event.allSelected,
                onlyDecline: true
            }
        });
        unwoAcceptRef.afterClosed().subscribe((res) => {
            if (res) {
                const { status, ...rest } = res;
                this.store.dispatch(
                    bulkActions.bulkUnwriteOff({
                        ...rest,
                        status: 'DECLINED'
                    })
                );
            }
        });
    }
}
