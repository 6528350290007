import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState, selectRouterState } from '../../core/core.state';
import { Store } from '@ngrx/store';
import {
    dashboardSelector,
    instanceSelector,
    profilingScansSelector,
    scanIdSelector,
    scanQualityGateStatusSelector,
    scansSelector,
    showScansSelect
} from '../../core/state/general/general.selectors';
import { GeneralActions } from '../../core/state/general/general.actions';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { selectCurrentUser } from '../../core/auth-lib';

const DEFAULT_URL =
    'https://docs.qualityclouds.com/qcd/quality-clouds-documentation-3997700.html';

@Component({
    selector: 'qcbi-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
    instance$: Observable<any>;
    scanId$: Observable<number | null>;
    scanQualityGateStatus$: Observable<string | null>;
    dashboard$: Observable<any>;
    showScansSelect$: Observable<boolean>;

    breadcrumbs$: Observable<string>;
    helpUrl$: Observable<string>;

    scans: any[] = [];
    profilingScans: any[] = [];

    selectableScans = [];
    selectedDashboardUrl = '';
    timeFormat;
    private unsubscribe = new Subject<void>();

    constructor(private store: Store<AppState>, public router: Router) {}

    ngOnInit(): void {
        this.store
            .select(selectCurrentUser)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(user => {
                this.timeFormat = user?.timeFormat;
            });
        this.scanId$ = this.store
            .select(scanIdSelector)
            .pipe(takeUntil(this.unsubscribe));
        this.scanQualityGateStatus$ = this.store
            .select(scanQualityGateStatusSelector)
            .pipe(takeUntil(this.unsubscribe));
        this.showScansSelect$ = this.store
            .select(showScansSelect)
            .pipe(takeUntil(this.unsubscribe));
        this.store
            .select(scansSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(scans => {
                this.scans = scans;
                this.setSelectableScans();
            });
        this.store
            .select(profilingScansSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(profilingScans => {
                this.profilingScans = profilingScans;
                this.setSelectableScans();
            });
        this.instance$ = this.store
            .select(instanceSelector)
            .pipe(takeUntil(this.unsubscribe));
        this.dashboard$ = this.store
            .select(dashboardSelector)
            .pipe(startWith({}), takeUntil(this.unsubscribe));
        this.helpUrl$ = this.store.select(dashboardSelector).pipe(
            startWith(DEFAULT_URL),
            map(
                dashboard => (dashboard && dashboard['help-url']) || DEFAULT_URL
            )
        );
        this.breadcrumbs$ = this.store.select(selectRouterState).pipe(
            map(data => {
                if (!data) return '';
                return data.state.url
                    .split('?')[0]
                    .split('/')
                    .reduce((result, urlSegment) => {
                        if (urlSegment !== '' && isNaN(+urlSegment)) {
                            urlSegment = urlSegment
                                .toLowerCase()
                                .replace(/\b\w/g, s => s.toUpperCase());
                            urlSegment = urlSegment.replace(
                                'Servicenow',
                                'ServiceNow'
                            );
                            result.push(urlSegment.replace(/-/g, ' '));
                        }
                        return result;
                    }, [])
                    .join(' / ');
            })
        );

        this.store
            .select(dashboardSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(dashboard => {
                this.selectedDashboardUrl = dashboard ? dashboard.url : '';
                this.setSelectableScans();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setSelectableScans() {
        if (this.selectedDashboardUrl === 'profiling') {
            this.selectableScans = this.profilingScans;
        } else if (this.selectedDashboardUrl === 'executive') {
            this.selectableScans = [];
            for (let i = 0; i < this.scans.length; i++) {
                this.selectableScans.push(this.scans[i]);
                if (this.scans[i].isBaseline) break;
            }
        } else {
            this.selectableScans = this.scans;
        }
    }

    handleScanChange({ value }) {
        const scan = this.selectableScans.find(x => +x.value === +value);
        this.store.dispatch(GeneralActions.setScan({ scan }));
    }
}
