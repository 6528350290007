import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    ViewChild
} from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    NgControl
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FocusMonitor } from '@angular/cdk/a11y';
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter
} from '@angular/material-moment-adapter';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/core.state';
import { selectCurrentUser } from '../../../../core/auth-lib';
import { Moment } from 'moment';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import {
    MAT_LEGACY_FORM_FIELD as MAT_FORM_FIELD,
    MatLegacyFormField as MatFormField,
    MatLegacyFormFieldControl as MatFormFieldControl
} from '@angular/material/legacy-form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
    selector: 'qcbi-expiration',
    templateUrl: 'expiration.component.html',
    styleUrls: ['expiratin.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: ExpirationComponent
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpirationComponent
    implements
        OnInit,
        OnDestroy,
        MatFormFieldControl<Date | ''>,
        ControlValueAccessor
{
    static nextId = 0;

    @ViewChild(MatInput, { read: ElementRef, static: true })
    input: ElementRef;

    @Input()
    required = false;

    @Input()
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    get placeholder() {
        return this._placeholder;
    }

    @Input()
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.form.disable() : this.form.enable();
        this.stateChanges.next();
    }
    get disabled() {
        return this._disabled;
    }

    get empty() {
        return false;
    }

    readonly autofilled = false;
    readonly controlType = 'custom-form-field-expiration';
    readonly shouldLabelFloat = true;
    readonly stateChanges: Subject<void> = new Subject();

    focused = false;
    touched = false;
    value: Date | '' | null;
    form: UntypedFormGroup;
    today = new Date();
    tomorrow = new Date();

    onChange: (value: Date | '') => void;
    onTouched: () => void;

    private _disabled;
    private _placeholder: string;
    private unsubscribe$ = new Subject();

    @HostBinding()
    readonly id = `${this.controlType}-${ExpirationComponent.nextId++}`;
    @HostBinding('attr.aria-describedby') describedBy = '';

    constructor(
        private focusMonitor: FocusMonitor,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
        private fb: UntypedFormBuilder,
        private _adapter: DateAdapter<any>,
        private store: Store<AppState>,
        private _elementRef: ElementRef<HTMLElement>
    ) {
        this.today.setHours(0, 0, 0, 0);
        if (this.ngControl !== null) this.ngControl.valueAccessor = this;
        this.form = this.fb.group({
            select: new UntypedFormControl(''),
            date: new UntypedFormControl({ value: '', required: false })
        });
    }

    ngOnInit(): void {
        this.tomorrow.setDate(this.tomorrow.getDate() + 1);
        this.store
            .select(selectCurrentUser)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((user) => {
                if (!user.timeFormat || user.timeFormat === 'EU') {
                    this._adapter.setLocale('en-GB');
                } else {
                    this._adapter.setLocale('en-US');
                }
            });
        this.form
            .get('select')
            .valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => {
                this.setDefaultValue(res);
                this.stateChanges.next();
            });
        this.form
            .get('date')
            .valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: Moment) => {
                if (res && res.toDate) {
                    this.stateChanges.next();
                    this.checkSelectValue(res.toDate());
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.stateChanges.complete();
        this.focusMonitor.stopMonitoring(this.input);
        (this.stateChanges as Subject<any>).complete();
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (
            !this._elementRef.nativeElement.contains(
                event.relatedTarget as Element
            )
        ) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    get errorState(): boolean {
        return this.form.invalid && this.form.touched;
    }

    /**
     * sets a default value on the date from the value param
     * @param value with the options from the selector
     */
    setDefaultValue(value: '1w' | '1m' | '1d' | 'sd' | '') {
        const d = new Date();
        switch (value) {
            case '1d':
                d.setDate(d.getDate() + 1);
                break;
            case '1w':
                d.setDate(d.getDate() + 7);
                break;
            case '1m':
                d.setDate(d.getDate() + 30);
                break;
            case '':
                this.value = null;
                this.form.patchValue({ date: null });
                if (this.onChange) this.onChange(null);
                return;
            default:
                console.error('unknown value', value);
                return;
        }
        if (this.onChange) this.onChange(d);
        this.form.get('date').setValue(d.toISOString(), { emitEvent: false });
    }

    /**
     * sets a new value on the selector from the date param specified
     * @param date param of the selected date
     */
    checkSelectValue(date: Date) {
        date.setHours(12);
        if (this.onChange) this.onChange(date);
        switch (
            (date.getTime() - this.today.getTime()) /
            (1000 * 60 * 60 * 24)
        ) {
            case 1:
                this.form.get('select').setValue('1d', { emitEvent: false });
                break;
            case 7:
                this.form.get('select').setValue('1w', { emitEvent: false });
                break;
            case 30:
                this.form.get('select').setValue('1m', { emitEvent: false });
                break;
            default:
                this.form.get('select').setValue('sd', { emitEvent: false });
        }
    }

    /**
     * method implemented from the MatFormFieldControl
     * @param event
     */
    onContainerClick(event: MouseEvent): void {
        // this.focusMonitor.focusVia(this.input, 'program');
    }

    /**
     * sets the described ids (implemented from MatFormFieldControl)
     * @param ids
     */
    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    /**
     * register the function on change to implement ControlValueAccessor
     * @param fn
     */
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * registers the function on touched to implement ControlValueAccessor
     * @param fn
     */
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * sets the disabled attribute from the values from ControlValueAccessor
     * @param isDisabled
     */
    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    /**
     * writes the value to the control accessed from ControlValueAccessor
     * @param obj
     */
    writeValue(obj: Date | ''): void {
        this.value = obj;
        if (obj instanceof Date) {
            this.form.get('date').setValue(obj);
            this.checkSelectValue(obj);
        }
    }
}
