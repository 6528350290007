import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/core.state';
import { TagsActions } from '../../../core/state/tags/tags.actions';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

@Component({
    selector: 'qcbi-tag-form',
    templateUrl: './tag-form.component.html'
})
export class TagFormComponent implements OnInit {
    inputNameValue;

    constructor(
        private store: Store<AppState>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<TagFormComponent>
    ) {}

    ngOnInit() {
        if (this.data && this.data.name) {
            this.inputNameValue = this.data.name;
        }
    }

    createTag() {
        if (this.data && this.data.id)
            this.store.dispatch(
                TagsActions.editTag({
                    tagName: this.inputNameValue,
                    id: this.data.id
                })
            );
        else
            this.store.dispatch(
                TagsActions.createTag({ tagName: this.inputNameValue })
            );
        this.dialogRef.close();
    }
}
