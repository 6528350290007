import { ElementRef, Injectable } from '@angular/core';
import {
    ActionableService,
    ActionDispatchedEvent
} from '../../../../../tables/services/action.models';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

@Injectable({
    providedIn: 'root'
})
export class AddTagActionService implements ActionableService {
    readonly actionId = 'add-tag';

    tagsAllSelected = false;
    assignTagsIds = [];
    unassignTagsIds = [];
    selectedItems: any[];
    tagsAndItems = {};
    items = [];
    tagsMenuAnchor: ElementRef;
    tagsMenu: MatMenuTrigger;

    constructor() {}

    executeAction(event: ActionDispatchedEvent): void {
        this.selectedItems = event.selectedItems;
        this.tagsAllSelected = event.allSelected;
        this.tagsAndItems = {};
        this.items = [];
        if (this.selectedItems)
            this.selectedItems.map((el) => {
                this.items.push(el['Element Id']);
                el.tags.map((tag) => {
                    if (!this.tagsAndItems.hasOwnProperty(tag.id))
                        this.tagsAndItems[tag.id] = [];
                    this.tagsAndItems[tag.id].push(el['Element Id']);
                });
            });
        let button: HTMLButtonElement;
        for (const el of event.event.composedPath()) {
            if ((el as Element).tagName === 'BUTTON') {
                button = el as HTMLButtonElement;
                break;
            }
        }
        if (button) {
            this.tagsMenuAnchor.nativeElement.style.top =
                event.event.pageY +
                button.offsetHeight -
                event.event.offsetY +
                'px';
            this.tagsMenuAnchor.nativeElement.style.left =
                event.event.pageX -
                event.event.offsetX -
                ((event.event.target as Element).tagName === 'BUTTON'
                    ? 0
                    : 15) +
                'px';
            this.tagsMenu.openMenu();
        }
    }

    registerVisualElements(
        tagsMenuAnchor: ElementRef,
        tagsMenu: MatMenuTrigger
    ) {
        this.tagsMenuAnchor = tagsMenuAnchor;
        this.tagsMenu = tagsMenu;
    }
}
