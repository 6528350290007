import { NgModule } from '@angular/core';
import { TableConfigurationComponent } from './table-configuration.component';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ClickOutsideModule } from '../../../shared/click-outside/click-outside.module';

@NgModule({
    imports: [
        CommonModule,
        DragDropModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatTooltipModule,
        ClickOutsideModule
    ],
    declarations: [TableConfigurationComponent],
    exports: [TableConfigurationComponent]
})
export class TableConfigurationModule {}
