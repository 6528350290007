import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface ModifyDateResult {
    expirationDate: string;
}

@Component({
    selector: 'qcbi-modify-write-off-expiration-date-dialog',
    templateUrl: 'modify-write-off-expiration-date-dialog.component.html'
})
export class ModifyWriteOffExpirationDateDialogComponent {
    expirationControl = new UntypedFormControl();

    constructor(
        public dialogRef: MatDialogRef<ModifyWriteOffExpirationDateDialogComponent>
    ) {}

    close() {
        this.dialogRef.close();
    }

    submitChanges() {
        const result: ModifyDateResult = {
            expirationDate: this.expirationControl.value
        };
        this.dialogRef.close(result);
    }
}
