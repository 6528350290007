import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { GeneralActions } from '../../../core/state/general/general.actions';
import { userPortalConfigurationSelector } from '../../../core/state/general/general.selectors';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/core.state';
import { Subject } from 'rxjs';

const INVISIBLE_COLUMNS = [
    'Scan Id',
    'Op Scan',
    'Update Set Scan Date',
    'Update Set ID',
    'Update Set User',
    'Quality Gate Result Message',
    'QgSkip',
    'QgSkipReason',
    'Id',
    'Sys Id',
    'Select',
    'Element Id',
    'IssueId',
    'Issue Id',
    'CeId',
    'OpenedAfterQgActivation'
];

@Component({
    selector: 'qcbi-table-configuration',
    templateUrl: 'table-configuration.component.html',
    styleUrls: ['./table-configuration.component.scss']
})
export class TableConfigurationComponent implements OnInit, OnDestroy {
    @Input() initialConfigValues: any;
    @Input() configuration: string;
    @Input() hideColumnsByDefault: string[];
    @Output() columnsConfigurationChanged = new EventEmitter<string[]>();
    @Output() configChanged = new EventEmitter<object>();
    columns = [];
    invisibleColumns = [];
    show = false;
    modified = false;
    configurationColumns = [];
    userTableConfig;
    private unsubscribe = new Subject<any>();

    constructor(private store: Store<AppState>) {}

    @Input()
    set originalColumns(value) {
        this.configurationColumns = value.filter(
            (c) => INVISIBLE_COLUMNS.indexOf(c) === -1
        );
        this.columns = value.filter((c) => INVISIBLE_COLUMNS.indexOf(c) === -1);
        this.invisibleColumns = value.filter(
            (c) => INVISIBLE_COLUMNS.indexOf(c) !== -1
        );
    }

    ngOnInit() {
        this.store
            .select(userPortalConfigurationSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((userPortalConfiguration) => {
                this.userTableConfig =
                    userPortalConfiguration[this.configuration] ||
                    this.initialConfigValues;
                if (userPortalConfiguration[this.configuration])
                    this.configChanged.emit({
                        col: userPortalConfiguration[this.configuration].col
                    });
                this.loadUserConfig();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    checkboxChangeHandler(event: MatCheckboxChange, column: string): void {
        this.modified = true;
        if (event.checked) {
            let originalIndex = 0;
            let index = 0;
            while (true) {
                if (this.configurationColumns[originalIndex] === column) {
                    break;
                }
                if (
                    this.columns[index] !==
                    this.configurationColumns[originalIndex]
                )
                    originalIndex = originalIndex + 1;
                else {
                    index = index + 1;
                    originalIndex = originalIndex + 1;
                }
            }
            this.columns.splice(index, 0, column);
        } else {
            this.columns = this.columns.filter((c) => c !== column);
        }
    }

    clickOutsideHandler(event) {
        if (
            !['config-button', 'config-icon'].includes(event[0].id) &&
            !['config-button', 'config-icon'].includes(event[0].parentNode.id)
        ) {
            this.toggleMenu();
        }
    }

    drop(event: CdkDragDrop<string[]>): void {
        this.modified = true;
        moveItemInArray(
            this.configurationColumns,
            event.previousIndex,
            event.currentIndex
        );
        this.columns = this.configurationColumns.filter((c) =>
            this.columns.find((o) => o === c)
        );
    }

    getCheckboxState(column: string): boolean {
        return this.columns.find((c) => c === column);
    }

    loadUserConfig() {
        if (this.userTableConfig) {
            this.configurationColumns.sort(
                (a, b) =>
                    this.userTableConfig.col.indexOf(a) -
                    this.userTableConfig.col.indexOf(b)
            );
            this.columns = this.userTableConfig.col.filter(
                (c) => INVISIBLE_COLUMNS.indexOf(c) === -1
            );
            const select = this.invisibleColumns.find(
                (e) => e.toUpperCase() === 'SELECT'
            );
            if (select)
                this.columnsConfigurationChanged.emit([
                    select,
                    ...this.columns,
                    ...this.invisibleColumns.filter(
                        (e) => e.toUpperCase() !== 'SELECT'
                    )
                ]);
            else
                this.columnsConfigurationChanged.emit([
                    ...this.columns,
                    ...this.invisibleColumns
                ]);
        } else if (this.hideColumnsByDefault) {
            this.configColumnsByDefault();
        }
    }

    toggleMenu(): void {
        this.show = !this.show;
        if (!this.show && this.modified) {
            this.modified = false;
            const col = [...this.columns];
            this.configChanged.emit({ col });
            this.store.dispatch(
                GeneralActions.setUserConfigurationItem({
                    key: this.configuration,
                    value: { col }
                })
            );
        }
    }

    configColumnsByDefault() {
        let defaultConfig = this.configurationColumns;
        for (let index = 0; index < this.hideColumnsByDefault.length; index++) {
            defaultConfig = defaultConfig.filter(
                (element) => element !== this.hideColumnsByDefault[index]
            );
        }

        if (defaultConfig) {
            this.configurationColumns.sort(
                (a, b) => defaultConfig.indexOf(a) - defaultConfig.indexOf(b)
            );
            this.columns = defaultConfig.filter(
                (c) => INVISIBLE_COLUMNS.indexOf(c) === -1
            );
            const select = this.invisibleColumns.find(
                (e) => e.toUpperCase() === 'SELECT'
            );
            if (select)
                this.columnsConfigurationChanged.emit([
                    select,
                    ...this.columns,
                    ...this.invisibleColumns.filter(
                        (e) => e.toUpperCase() !== 'SELECT'
                    )
                ]);
            else
                this.columnsConfigurationChanged.emit([
                    ...this.columns,
                    ...this.invisibleColumns
                ]);
        }
    }
}
