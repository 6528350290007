import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { Observable, Subject } from 'rxjs';
import { AppState } from '../../../core/core.state';
import { select, Store } from '@ngrx/store';
import {
    allTagsSelector,
    isLoadedSelector
} from '../../../core/state/tags/tags.selectors';
import { takeUntil } from 'rxjs/operators';
import { TagsActions } from '../../../core/state/tags/tags.actions';
import { TagFormComponent } from '../tag-form/tag-form.component';
import { TagManagerComponent } from '../tag-manager/tag-manager.component';

@Component({
    selector: 'qcbi-tag-select',
    templateUrl: './tag-select.component.html',
    styleUrls: ['./tag-select.component.scss']
})
export class TagSelectComponent implements OnInit, OnDestroy {
    @Input() instanceLabels: any[];
    @Input() customerId: number;
    @Input() tagsAndItems = {};
    @Input() items = [];

    @Output() selectionChange = new EventEmitter<{
        value: any;
        selected: boolean;
    }>();

    tags$: Observable<any>;

    unsubscribe$ = new Subject();

    constructor(public dialog: MatDialog, private store: Store<AppState>) {}

    ngOnInit() {
        this.store
            .select(isLoadedSelector)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isLoaded) => {
                if (!isLoaded) this.store.dispatch(TagsActions.fetchTags());
            });

        this.tags$ = this.store.pipe(select(allTagsSelector));
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    addTag() {
        this.dialog.open(TagFormComponent);
    }

    manage() {
        this.dialog.open(TagManagerComponent);
    }

    checkboxChange(event: MatCheckboxChange) {
        this.selectionChange.emit({
            value: event.source.value,
            selected: event.checked
        });
    }
}
