import { Action, createReducer, on } from '@ngrx/store';
import { StateConfig } from './dm-new-view.config';
import { FilterOptionsDictionary } from '../../../../shared/selectable-filters/selectable-filters.component';
import { dmNewViewActions } from './dm-new-view.actions';
import { GeneralActions } from '../../general/general.actions';
import { MAX_OPENED_VIEWS } from './dm-new-view.selectors';
import { SortDirection } from '@angular/material/sort';

export type DebtManagerView = {
    id: string;
    name: string;
    description?: string;
    isPrivate: boolean;
    viewSettings: {
        filters: FilterOptionsDictionary;
        columnsConfig?: { col: string[] };
        pageSize?: number;
        sort?: string;
        direction?: SortDirection;
    };
    isPinned: boolean;
    sharedWith?: number[] | null;
};

export type IsOwned = {
    userId: number;
};

export type OwnedView = DebtManagerView & IsOwned;

export type NewView = Omit<DebtManagerView, 'id'>;

export type OwnedViewDetails = Omit<OwnedView, 'filters'>;

export type OpenedViewRef = {
    id: string;
    name: string;
    isPinned: boolean;
    description?: string;
};

export type AvailableViews = {
    default: DebtManagerView[];
    myViews: OwnedView[];
    sharedViews: OwnedView[];
};

export enum Status {
    SAVING = 'SAVING',
    ERROR = 'ERROR',
    ERROR_LOADING_VIEWS = 'ERROR LOADING VIEWS',
    DELETING = 'DELETING',
    UPDATING_FILTERS = 'UPDATING FILTERS'
}

export type State = {
    status: Status | undefined;
    availableViews: AvailableViews;
    openedViews: OpenedViewRef[];
};

export type FeatureState = {
    [StateConfig.Key]: State;
};

const initialState: State = {
    status: undefined,
    availableViews: {
        default: [],
        myViews: [],
        sharedViews: []
    },
    openedViews: []
};

const createViewRefFromView = (view: DebtManagerView): OpenedViewRef => {
    return {
        id: view.id,
        description: view.description,
        name: view.name,
        isPinned: false
    };
};

const reducers = createReducer(
    initialState,
    on(
        dmNewViewActions.createView,
        dmNewViewActions.updateView,
        (state: State) => ({
            ...state,
            status: Status.SAVING
        })
    ),
    on(GeneralActions.instanceLoad, (state: State) => ({
        ...state,
        openedViews: []
    })),
    on(dmNewViewActions.openSavedViewNew, (state: State, { newView }) => {
        const newViewRef: OpenedViewRef = createViewRefFromView(newView);
        return {
            ...state,
            openedViews: [...state.openedViews, newViewRef]
        };
    }),
    on(dmNewViewActions.createViewSuccess, (state: State, { newView }) => {
        const newViewRef: OpenedViewRef = createViewRefFromView(newView);
        const myViewsNew = [...state.availableViews.myViews];
        const mySharedViewsNew = [...state.availableViews.sharedViews];
        if (newView.isPrivate) {
            myViewsNew.unshift(newView);
        } else {
            mySharedViewsNew.unshift(newView);
        }
        const newOpenedViews = [...state.openedViews];
        if (newOpenedViews.length < MAX_OPENED_VIEWS)
            newOpenedViews.push(newViewRef);
        return {
            ...state,
            status: undefined,
            openedViews: newOpenedViews,
            availableViews: {
                ...state.availableViews,
                myViews: myViewsNew,
                sharedViews: mySharedViewsNew
            }
        };
    }),
    on(dmNewViewActions.updateViewSuccess, (state: State, { viewToUpdate }) => {
        let myEditingView = [
            ...state.availableViews.myViews,
            ...state.availableViews.sharedViews
        ].find(v => v.id === viewToUpdate.id);
        if (!myEditingView) console.error('view not found');
        myEditingView = {
            ...myEditingView,
            ...viewToUpdate
        };
        const myViewsNew = state.availableViews.myViews.filter(
            v => v.id !== viewToUpdate.id
        );
        const mySharedViewsNew = state.availableViews.sharedViews.filter(
            v => v.id !== viewToUpdate.id
        );
        const openedViews = [...state.openedViews];
        if (myEditingView.isPrivate) {
            myViewsNew.unshift(myEditingView);
        } else {
            mySharedViewsNew.unshift(myEditingView);
        }
        const openedIndex = openedViews.findIndex(
            v => v.id === viewToUpdate.id
        );
        if (openedIndex !== -1) {
            openedViews[openedIndex] = {
                ...openedViews[openedIndex],
                name: viewToUpdate.name,
                description: viewToUpdate.description
            };
        }
        return {
            ...state,
            status: undefined,
            availableViews: {
                ...state.availableViews,
                myViews: myViewsNew,
                sharedViews: mySharedViewsNew
            },
            openedViews
        };
    }),
    on(
        dmNewViewActions.updateCurrentViewWithCurrentFilters,
        (state: State) => ({
            ...state,
            status: Status.UPDATING_FILTERS
        })
    ),
    on(
        dmNewViewActions.updateCurrentViewFiltersSuccess,
        (
            state: State,
            { filters, viewId, columnsConfig, pageSize, sort, direction }
        ) => {
            let myEditingView = [
                ...state.availableViews.myViews,
                ...state.availableViews.sharedViews
            ].find(v => v.id === viewId);
            if (!myEditingView) console.error('view not found');
            myEditingView = {
                ...myEditingView,
                viewSettings: {
                    filters,
                    columnsConfig,
                    pageSize,
                    sort,
                    direction
                }
            };
            const myViewsNew = [...state.availableViews.myViews];
            const mySharedViewsNew = [...state.availableViews.sharedViews];
            let index = myViewsNew.findIndex(v => v.id === viewId);
            if (index !== -1) {
                myViewsNew.splice(index, 1, myEditingView);
            }
            index = mySharedViewsNew.findIndex(v => v.id === viewId);
            if (index !== -1) {
                mySharedViewsNew.splice(index, 1, myEditingView);
            }
            return {
                ...state,
                availableViews: {
                    ...state.availableViews,
                    myViews: myViewsNew,
                    sharedViews: mySharedViewsNew
                },
                status: undefined
            };
        }
    ),
    on(dmNewViewActions.closeView, (state: State, { viewId }) => {
        const newOpenedViews = state.openedViews.filter(v => v.id !== viewId);
        return {
            ...state,
            openedViews: newOpenedViews
        };
    }),
    on(dmNewViewActions.closeOtherViews, (state: State, { viewId }) => {
        return {
            ...state,
            openedViews: state.openedViews.filter(
                v => v.id === viewId || v.isPinned
            )
        };
    }),
    on(dmNewViewActions.closeAllViews, (state: State) => {
        return {
            ...state,
            openedViews: state.openedViews.filter(v => v.isPinned)
        };
    }),
    on(dmNewViewActions.getSavedViews, (state: State) => ({
        ...state,
        status: undefined
    })),
    on(dmNewViewActions.error, (state: State) => ({
        ...state,
        status: Status.ERROR
    })),
    on(dmNewViewActions.getSavedViewsError, (state: State) => ({
        ...state,
        status: Status.ERROR_LOADING_VIEWS
    })),
    on(
        dmNewViewActions.getSavedViewsSuccess,
        (state: State, { myViews, default: defaultViews, sharedViews }) => {
            return {
                ...state,
                availableViews: {
                    myViews,
                    sharedViews,
                    default: defaultViews
                },
                openedViews: [
                    ...myViews,
                    ...defaultViews,
                    ...sharedViews
                ].reduce((result, next) => {
                    if (next.isPinned) {
                        const viewRef: OpenedViewRef = {
                            id: next.id,
                            description: next.description,
                            name: next.name,
                            isPinned: next.isPinned
                        };
                        result.push(viewRef);
                    }
                    return result;
                }, [])
            };
        }
    ),
    on(dmNewViewActions.deleteCurrentView, (state: State) => ({
        ...state,
        status: Status.DELETING
    })),
    on(dmNewViewActions.deleteViewSuccess, (state: State, { viewId }) => {
        const newMyViews = state.availableViews.myViews.filter(
            mv => mv.id !== viewId
        );
        const myNewShared = state.availableViews.sharedViews.filter(
            v => v.id !== viewId
        );
        return {
            ...state,
            status: undefined,
            availableViews: {
                ...state.availableViews,
                myViews: newMyViews,
                sharedViews: myNewShared
            }
        };
    }),
    on(dmNewViewActions.pinView, (state: State, { viewId }) => {
        const indexOfView = state.openedViews.findIndex(v => v.id === viewId);
        const newOpenedViews = [...state.openedViews];
        const pinnedView = { ...newOpenedViews.splice(indexOfView, 1)[0] };
        pinnedView.isPinned = !pinnedView.isPinned;
        return {
            ...state,
            openedViews: [pinnedView, ...newOpenedViews]
        };
    }),
    on(dmNewViewActions.unpinView, (state: State, { viewId }) => {
        const indexOfUnpinnedView = state.openedViews.findIndex(
            v => v.id === viewId
        );
        const newOpenedViews = [...state.openedViews];
        const unpinnedView = {
            ...newOpenedViews.splice(indexOfUnpinnedView, 1)[0]
        };
        unpinnedView.isPinned = !unpinnedView.isPinned;
        const indexOfFirstUnpinnedView = newOpenedViews.findIndex(
            v => v.isPinned === false
        );
        if (indexOfFirstUnpinnedView === -1) newOpenedViews.push(unpinnedView);
        else newOpenedViews.splice(indexOfFirstUnpinnedView, 0, unpinnedView);
        return {
            ...state,
            openedViews: newOpenedViews
        };
    })
);

export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}
