<div
    class="h-18 flex justify-left items-center header"
    [ngClass]="{ logo: expanded$ | async, 'logo-small': !(expanded$ | async) }"
    id="logo"
>
    <a class="w-full" href="#" role="navigation" aria-label="reload page">
        <img
            *ngIf="expanded$ | async"
            id="large"
            src="./assets/logos/white-logo.png"
            alt="Quality Clouds"
        />
        <img
            *ngIf="!(expanded$ | async)"
            id="small"
            src="./assets/logos/only_magnifier.png"
            alt="Quality Clouds"
        />
    </a>
</div>

<div class="top-buttons" [class.min-menu]="!(expanded$ | async)">
    <button
        mat-button
        color="primary"
        class="group"
        role="button"
        *ngFor="let node of dataSource.top"
        [disabled]="node.disabled"
        (click)="handleClick(node)"
        [class.selected]="(onAllClouds$ | async) === true && node.type === 'all-clouds'"
    >
        <div class="nav-container">
            <mat-icon *ngIf="node.icon">
                {{ node.icon }}
            </mat-icon>
            <img
                *ngIf="node.image"
                [src]="node.image"
                [alt]="node.name"
            />
            <div class="ml-4" *ngIf="expanded$ | async">
                <h1>{{ node.name }}</h1>
            </div>
        </div>
    </button>
</div>

<mat-tab-group mat-stretch-tabs dynamicHeight class="example-stretched-tabs"
               [ngClass]="{ 'expanded': expanded$ | async }">
    <mat-tab label="instances list" aria-label="instances list" role="navigation">
        <ng-template mat-tab-label>
            <mat-icon [matTooltip]="'Instances list'" class="example-tab-icon">list</mat-icon>
        </ng-template>
        <mat-tree
            [style.bottom]="dataSource.bottom.length * 55 + 'px'"
            [style.top]="dataSource.top.length * 55 + 'px'"
            [style.height]="'calc(100vh - ' + ((dataSource.top.length + dataSource.bottom.length) * 55 + 80 + 48 + 87) + 'px)'"
            [dataSource]="dataSource.services"
            [treeControl]="tc"
            [class.min-menu]="!(expanded$ | async)"
        >
            <mat-tree-node
                *matTreeNodeDef="let node"
                [class.mt-auto]="node.position === 'bottom'"
            >
                <button
                    mat-button
                    *ngIf="node.type === 'instance'"
                    color="primary"
                    class="instance"
                    role="button"
                    [class.selected]="node.instanceId === (instanceId$ | async) && !(onAllClouds$ | async)"
                    [class.highlight]="node.serviceId === (serviceId$ | async) && node.instanceId !== (instanceId$ | async) && !(onAllClouds$ | async)"
                    (click)="handleClick(node)"
                    [matTooltip]="node.name"
                >
                    <p>
                        {{
                            node.name.length > 15
                                ? (node.name | slice: 0:15) + '...'
                                : node.name
                        }}
                    </p>
                </button>
                <button
                    mat-button
                    *ngIf="
                node.type === 'all-clouds' ||
                node.type === 'link' ||
                node.type === 'help'
            "
                    color="primary"
                    role="button"
                    class="group"
                    [class.selected]="
                (onAllClouds$ | async) && node.type === 'all-clouds'
            "
                    (click)="handleClick(node)"
                >
                    <div class="nav-container">
                        <mat-icon *ngIf="node.icon">
                            {{ node.icon }}
                        </mat-icon>
                        <img *ngIf="node.image" [src]="node.image" [alt]="node.name" />
                        <div class="ml-4" *ngIf="expanded$ | async">
                            <h1>{{ node.name }}</h1>
                        </div>
                    </div>
                </button>
            </mat-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <button
                    class="group"
                    color="primary"
                    role="button"
                    [class.expanded]="tc.isExpanded(node)"
                    [class.selected]="node.id === (serviceId$ | async) && !(onAllClouds$ | async)"
                    [disabled]="node.disabled"
                    mat-icon-button
                    matTreeNodeToggle
                    (click)="handleClick(node)"
                >
                    <div class="nav-container">
                        <mat-icon *ngIf="node.icon">
                            {{ node.icon }}
                        </mat-icon>
                        <img *ngIf="node.image" [src]="node.image" [alt]="node.name" />
                        <div class="ml-4" *ngIf="expanded$ | async">
                            <h1>{{ node.name }}</h1>
                            <h3 *ngIf="node.name==='Salesforce' && node.children">
                                {{ node.children.length }} {{ node.children.length == 1 ? 'org' : 'orgs' }}
                            </h3>
                            <h3 *ngIf="node.name!=='Salesforce' && node.children">
                                {{ node.children.length }} {{ node.children.length == 1 ? 'instance' : 'instances' }}
                            </h3>
                        </div>
                    </div>
                </button>
                <div class="position-relative"
                     *ngIf="(node.filter !== '' || originalHasChildren(node)) && tc.isExpanded(node)">
                    <input
                        matInput
                        autocomplete="off"
                        class="search-input"
                        [(ngModel)]="node.filter"
                        (change)="inputChangeHandler(node)"
                        role="textbox"
                        aria-label="search"
                    />
                    <mat-icon matSuffix class="input-icon">search</mat-icon>
                </div>
                <div
                    *ngIf="!node.disabled"
                    class="instances"
                    [style.height]="((expanded$ | async) ? (node.children.length * 45 ) : 0 )+ 'px'"
                    [class.invisible]="!tc.isExpanded(node)"
                >
                    <ng-container matTreeNodeOutlet></ng-container>
                </div>
            </mat-nested-tree-node>
        </mat-tree>
    </mat-tab>

    <mat-tab label="starred" aria-label="starred instances" role="navigation">
        <ng-template mat-tab-label>
            <mat-icon [matTooltip]="'Starred Instances'" class="example-tab-icon material-icons-outlined">star_border</mat-icon>
        </ng-template>
        <mat-tree
            *ngIf="(dataSourceStarred$ | async) as dataSourceStarred"
            [style.bottom]="dataSourceStarred.bottom.length * 55 + 'px'"
            [style.top]="dataSourceStarred.top.length * 55 + 'px'"
            [style.height]="'calc(100vh - ' + ((dataSourceStarred.top.length + dataSourceStarred.bottom.length) * 55 + 80 + 48) + 'px)'"
            [dataSource]="dataSourceStarred['services']"
            [treeControl]="tc2"
            [class.min-menu]="!(expanded$ | async)"
        >
            <mat-tree-node
                *matTreeNodeDef="let node"
                [class.mt-auto]="node.position === 'bottom'"
            >
                <button
                    mat-button
                    *ngIf="node.type === 'instance'"
                    color="primary"
                    role="button"
                    class="instance"
                    [class.selected]="node.instanceId === (instanceId$ | async) && !(onAllClouds$ | async)"
                    [class.highlight]="node.serviceId === (serviceId$ | async) && node.instanceId !== (instanceId$ | async) && !(onAllClouds$ | async)"
                    (click)="handleClick(node)"
                    [matTooltip]="node.name"
                >
                    <p>
                        {{
                            node.name.length > 15
                                ? (node.name | slice: 0:15) + '...'
                                : node.name
                        }}
                    </p>
                </button>
                <button
                    mat-button
                    *ngIf="node.type === 'all-clouds' || node.type === 'link' || node.type === 'help'"
                    color="primary"
                    role="button"
                    class="group"
                    [class.selected]="(onAllClouds$ | async) && node.type === 'all-clouds'"
                    (click)="handleClick(node)"
                >
                    <div class="nav-container">
                        <mat-icon *ngIf="node.icon">
                            {{ node.icon }}
                        </mat-icon>
                        <img *ngIf="node.image" [src]="node.image" [alt]="node.name" />
                        <div class="ml-4">
                            <h1>{{ node.name }}</h1>
                        </div>
                    </div>
                </button>
            </mat-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <button
                    class="group"
                    color="primary"
                    role="button"
                    [class.expanded]="tc2.isExpanded(node)"
                    [class.selected]="node.id === (serviceId$ | async) && !(onAllClouds$ | async)"
                    [disabled]="node.disabled"
                    mat-icon-button
                    matTreeNodeToggle
                    (click)="handleClick(node)"
                >
                    <div class="nav-container">
                        <mat-icon *ngIf="node.icon">
                            {{ node.icon }}
                        </mat-icon>
                        <img *ngIf="node.image" [src]="node.image" [alt]="node.name" />
                        <div class="ml-4">
                            <h1>{{ node.name }}</h1>
                            <h3 *ngIf="node.name==='Salesforce' && node.children">
                                {{ node.children.length }} {{ node.children.length == 1 ? 'org' : 'orgs' }}
                            </h3>
                            <h3 *ngIf="node.name!=='Salesforce' && node.children">
                                {{ node.children.length }} {{ node.children.length == 1 ? 'instance' : 'instances' }}
                            </h3>
                        </div>
                    </div>
                </button>
                <div
                    *ngIf="!node.disabled"
                    class="instances"
                    [style.height]="((expanded$ | async) ? (node.children.length * 45 ) : 0 )+ 'px'"
                    [class.invisible]="!tc2.isExpanded(node)"
                >
                    <ng-container matTreeNodeOutlet></ng-container>
                </div>
            </mat-nested-tree-node>
        </mat-tree>
    </mat-tab>
</mat-tab-group>

<div class="bottom-buttons" [class.min-menu]="!(expanded$ | async)">
    <button
        mat-button
        color="primary"
        class="group help_buttons"
        role="button"
        *ngFor="let node of dataSource.bottom"
        (click)="handleClick(node)"
    >
        <div class="nav-container">
            <mat-icon *ngIf="node.icon" class="material-icons-outlined">
                {{ node.icon }}
            </mat-icon>
            <img
                *ngIf="node.image"
                [src]="node.image"
                [alt]="node.name"
            />
            <div class="ml-4 mb-2">
                <h1>{{ node.name }}</h1>
            </div>
        </div>
    </button>
    <div *ngIf="(expanded$ | async)" class="copyright">
        <ul>
            <li style="margin-right: 8rem; ">
                <div>{{year}} ©</div> 
                <div >
                    <a style="color: #17b098;" href="https://www.qualityclouds.com" title="quality clouds" target="_blank" class="m-link" rel="noopener noreferrer">
                        QualityClouds
                    </a>
                </div>
                <div aria-label="version" class="version text-sm">version : <a href="https://qualityclouds.com/documentation/whats-new/" target="_blank">{{version$ | async}}</a></div> 
            </li>
        </ul>
    </div>
</div>

<button
    [attr.aria-label]="!(expanded$ | async) ? 'expand side menu' : 'collapse side menu'"
    mat-fab
    (click)="expandedToggle()" class="show-hide"
>
    <mat-icon>
        {{ !(expanded$ | async) ? 'keyboard_arrow_right' : 'keyboard_arrow_left' }}
    </mat-icon>
</button>
