import { Injectable } from '@angular/core';
import {
    ActionableService,
    ActionDispatchedEvent
} from '../../../../../tables/services/action.models';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AssignTeamDialogComponent } from '../../../../../../shared/assign-team-dialog/assign-team-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class TeamsActionService implements ActionableService {
    readonly actionId = 'teams';

    constructor(private dialog: MatDialog) {}

    executeAction(event: ActionDispatchedEvent): void {
        this.dialog.open(AssignTeamDialogComponent, {
            width: '400px',
            data: {
                allSelected: event.allSelected,
                selectedItems: event.selectedItems
            }
        });
    }
}
