import {
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    scan,
    startWith,
    switchMap,
    tap
} from 'rxjs/operators';

export type FilterFunction<T> = (
    search: string,
    currentPage: number
) => Observable<{ data: T[]; nextPage: number }>;

@Component({
    selector: 'qcbi-search-multi-selector',
    templateUrl: 'search-multi-selector.component.html',
    styles: [
        `
            .no-items {
                color: #9e9a9a;
                font-style: italic;
            }
        `
    ]
})
export class SearchMultiSelectorComponent<T> implements OnInit {
    filteredOptions$: Observable<T[]>;

    formGroup: UntypedFormGroup;
    loading = false;

    @Output() elementRemovedFromList = new EventEmitter<T>();
    @Output() elementAddedToList = new EventEmitter<T>();

    @ViewChild('defaultChipContent', { static: true })
    defaultChipContent: TemplateRef<any>;
    @ViewChild('defaultOptionContent', { static: true })
    defaultOptionContent: TemplateRef<any>;

    @ContentChild('chipContent') chipContent: TemplateRef<any>;
    @ContentChild('optionContent') optionContent: TemplateRef<any>;

    @Input() selectedItems: T[] = [];
    @Input() displayAttribute = 'name';
    @Input() noItemsText = 'there are no items assigned';

    private nextPage$ = new Subject();

    @Input() filterFunction: FilterFunction<T> = () =>
        of({
            nextPage: -1,
            data: []
        });

    constructor(private formBuilder: UntypedFormBuilder) {}

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            search: ['']
        });
        this.filteredOptions$ = this.formGroup.get('search').valueChanges.pipe(
            startWith(''),
            debounceTime(500),
            distinctUntilChanged(),
            switchMap(search => {
                let currentPage = 1;
                return this.nextPage$.pipe(
                    startWith(currentPage),
                    tap(() => (this.loading = true)),
                    switchMap(() =>
                        typeof search !== 'string'
                            ? of({
                                  data: [],
                                  nextPage: -1
                              })
                            : this.filterFunction(search, currentPage)
                    ),
                    map(data => {
                        currentPage = data.nextPage;
                        return data.data;
                    }),
                    scan(
                        (allOptions: T[], newOptions: T[]) =>
                            allOptions.concat(newOptions),
                        []
                    ),
                    tap(() => (this.loading = false))
                );
            })
        );
    }

    getChipTemplate() {
        return this.chipContent || this.defaultChipContent;
    }

    getOption() {
        return this.optionContent || this.defaultOptionContent;
    }

    removeElement(element: T) {
        this.elementRemovedFromList.emit(element);
    }

    addElement(element: T) {
        this.elementAddedToList.emit(element);
        this.formGroup.reset(undefined, { emitEvent: false });
    }
}
