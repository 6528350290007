<div *ngIf="downloadType !== 'none'" class="row">
    <div class="col-auto ml-auto whitespace-no-wrap">
        <button
            mat-button
            (click)="downloadIssues(DownloadFormats.xls)"
            [loading]="downloadLoadingXls$ | async"
            [disabled]="loading || (paginator.length > MAX_TO_DOWNLOAD && downloadType.includes('issue'))"
            matTooltip="Export is limited to 60k elements. Consider applying filters to reduce the count"
            [matTooltipDisabled]="paginator.length <= MAX_TO_DOWNLOAD || !downloadType.includes('issue')"
        >
            <mat-icon>save_alt</mat-icon>
            <span class="ml-1 download-xls">Export XLS</span>
        </button>
        <ng-container
            *ngIf="downloadType == 'issues' || downloadType == 'open-issue' || downloadType == 'compare-issues'"
        >
            <button
                mat-button
                (click)="downloadIssues(DownloadFormats.sarif)"
                [loading]="downloadLoadingSarif$ | async"
            >
                <mat-icon>save_alt</mat-icon>
                <span class="ml-1 download-xls">Export SARIF</span>
            </button>
        </ng-container>
    </div>
</div>