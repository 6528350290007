import { Component } from '@angular/core';

@Component({
    selector: 'qcbi-chart-loader',
    template: `
        <ng-container>
            <div class="chart-loader-container">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
            </div>
            <p class="loading-text">loading chart...</p>
        </ng-container>
    `,
    styleUrls: ['chart-loader.component.scss']
})
export class ChartLoaderComponent {}
