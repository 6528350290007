import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
    selector: 'qcbi-write-off-dialog',
    templateUrl: './write-off-dialog.component.html'
})
export class WriteOffDialogComponent implements OnInit {
    reasonFormControl = new UntypedFormControl('', Validators.required);
    descriptionFormControl = new UntypedFormControl('', Validators.required);
    expirationControl = new UntypedFormControl('', Validators.required);

    reasons: any;

    constructor(
        public dialogRef: MatDialogRef<WriteOffDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private http: HttpClient
    ) {}

    ngOnInit() {
        this.getWriteOffReason()
            .pipe(first())
            .subscribe((res) => {
                this.reasons = res['data'].map((el) => {
                    return el.attributes;
                });
            });
    }

    validFields() {
        return (
            this.reasonFormControl.valid && this.descriptionFormControl.valid
        );
    }

    close() {
        this.dialogRef.close();
    }

    getWriteOffReason() {
        return this.http.get(
            `${environment.apiUrl}/v2/write-off-reason?filter[active]=true&sort=reason`
        );
    }

    submitChanges() {
        const reasonNeedle = this.reasonFormControl.value;
        const reason = this.reasons.find((r) => r.reason === reasonNeedle);
        this.dialogRef.close({
            ids: this.data.ids,
            reasonNameId: reason['name-id'],
            description: Array.isArray(this.descriptionFormControl.value)
                ? this.descriptionFormControl.value[0]
                : this.descriptionFormControl.value,
            expirationDate: this.expirationControl.value,
            allSelected: this.data.allSelected
        });
    }
}
