import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TwoFactorAuthenticationComponent } from './two-factor-authentication.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TwoFactorAuthenticationDialogComponent } from './components/two-factor-authentication-dialog/two-factor-authentication-dialog.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FormsModule } from '@angular/forms';
import { QRCodeModule } from 'angular2-qrcode';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { CodeDialogComponent } from './components/code-dialog/code-dialog.component';
import { NumCodeInputModule } from '../num-code-input/num-code-input.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatButtonModule,
        FormsModule,
        QRCodeModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatFormFieldModule,
        NumCodeInputModule,
        MatIconModule
    ],
    declarations: [
        TwoFactorAuthenticationComponent,
        TwoFactorAuthenticationDialogComponent,
        CodeDialogComponent
    ],
    exports: [TwoFactorAuthenticationComponent]
})
export class TwoFactorAuthenticationModule {}
