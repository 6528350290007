import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';
import { Table } from '../../abstracts/table.component';
import { NotificationService } from '../../core/notifications/notification.service';
import { Sort } from '@angular/material/sort';
import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import { ColorService } from '../../core/services/color.service';

@Component({
    selector: 'qcbi-default-table',
    templateUrl: './table.component.html',
    // TODO: move this to the abstract class with an animation builder
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            )
        ])
    ]
})
export class DefaultTableComponent extends Table implements OnChanges {
    @Input() loading: boolean;
    constructor(
        public store: Store<AppState>,
        public notificationService: NotificationService,
        public colorService: ColorService
    ) {
        super(store, notificationService, colorService);
    }

    ngOnChanges() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        if (this.defaultSortingColumn && this.defaultSortingDirection) {
            const sortState: Sort = {
                active: this.defaultSortingColumn,
                direction: this.defaultSortingDirection
            };
            this.dataSource.sort.active = sortState.active;
            this.dataSource.sort.direction = sortState.direction;
            this.dataSource.sort.start = this.defaultSortingDirection;
            this.dataSource.sort.sortChange.emit(sortState);
        }
    }
}
