import { IUserAdoptionSmall } from './small/usage/ua.model';
import { ILicenseSmall } from './small/usage/li.model';
import { IGovernorLimitStorageSmall } from './small/usage/gls.model';
import { IGovernorLimitApiCallsSmall } from './small/usage/glac.model';
import { IUserAdoptionLarge } from './large/usage/ua.model';
import { ILicenseLarge } from './large/usage/li.model';
import { IGovernorLimitStorageLarge } from './large/usage/gls.model';
import { IGovernorLimitApiCallsLarge } from './large/usage/glac.model';
import { IUsageSmall } from './small/livecheck-overview/usage.model';
import { IUsageLarge } from './large/livecheck-overview/usage.model';
import { IEarlyDetectionSmall } from './small/livecheck-overview/early-detection.model';
import { IEarlyDetectionLarge } from './large/livecheck-overview/early-detection.model';
import { IRmAppSmall } from './small/livecheck-overview/rm-app.model';
import { IRmHistSmall } from './small/livecheck-overview/rm-hist.model';
import { IFbSmall } from './small/livecheck-overview/fb.model';
import { IConfigurationElementsUpgradeabilitySmall } from './small/upgradeability/ceu.model';
import { IOOTBUpgradeabilitySmall } from './small/upgradeability/ootb.model';
import { IOOTBInventoryUpgradeabilitySmall } from './small/upgradeability/ootb-inventory.model';
import { IOOTBInventoryUpgradeabilityLarge } from './large/upgradeability/ootb-inventory.model';
import { ISalesforceSmall } from './small/governance-overview/salesforce.model';
import { IServiceNowSmall } from './small/governance-overview/service-now.model';
import { IServiceNowLarge } from './large/governance-overview/service-now.model';
import { ISalesforceLarge } from './large/governance-overview/salesforce.model';
import { IAlertsSmall } from './small/governance-overview/al-small.model';
import { IAssetManagerLarge } from './large/asset-manager/asset-manager.model';
import { ICustomTablesSmall } from './small/profiling/ct.model';
import { INewLocsSmall } from './small/profiling/nlocs.model';
import { INewCeSmall } from './small/profiling/nce.model';
import { IWarningsSmall } from './small/profiling/warn.model';
import { IApplicationsLarge } from './large/profiling/apps.model';
import { ICustomTablesLarge } from './large/profiling/ct.model';
import { IIssuesLarge } from './large/profiling/iss.model';
import { ILocsProfilingLarge } from './large/profiling/locs.model';
import { INLocsProfilingLarge } from './large/profiling/nlocs.model';
import { ITotalApplicationsProfilingSmall } from './small/profiling/apps.model';
import { INewConfigurationElementsLarge } from './large/profiling/nce.model';
import { IWarningsLarge } from './large/profiling/warn.model';
import { ICMILarge } from './large/compare-multiple-instances/cmi-large.model';
import { IIntegrationsSmall } from './small/compare-multiple-issues/int.model';
import { IMostConfigurableAreasLarge } from './large/most-configurable-areas/mca.model';
import { IAuSmall } from './small/user-adoption/au.model';
import { ITlSmall } from './small/user-adoption/tl.model';
import { IActiveUsersLarge } from './large/user-adoption/au.model';
import { ITotalLoginsLarge } from './large/user-adoption/tl.model';
import { ITestCoverageSmall } from './small/profiling/tc.model';
import { ICodeChangesIssuesRatioSmall } from './small/profiling/cctir.model';
import { ICodeChangesToIssuesRatioLarge } from './large/profiling/cctir.model';
import { INocSmall } from './small/catalog/noc.model';
import { ICcSmall } from './small/catalog/cc.model';
import { ICvSmall } from './small/catalog/cv.model';
import { ICiSmall } from './small/catalog/ci.model';
import { ICatalogIssuesSmall } from './small/catalog/issues.model';
import { INumberOfCatalogsLarge } from './large/catalog/noc.model';
import { ICatalogCategoriesLarge } from './large/catalog/cc.model';
import { ICatalogItemsLarge } from './large/catalog/ci.model';
import { ICatalogVariablesLarge } from './large/catalog/cv.model';
import { ICatalogIssuesWarningLarge } from './large/catalog/issues.model';
import { ILicensesSmall } from './small/licensing/licenses.model';
import { IGlsSmall } from './small/licensing/gls.model';
import { IGlacSmall } from './small/licensing/glac.model';
import { ILicensesLarge } from './large/licensing/licenses.model';
import { IGlsLarge } from './large/licensing/gls.model';
import { IGlacLarge } from './large/licensing/glac.model';
import { ICstSmall } from './small/performance/cst.model';
import { ISstSmall } from './small/performance/sst.model';
import { IPiSmall } from './small/performance/pi.model';
import { IClientSlowTransactionsLarge } from './large/performance/cst.model';
import { IServerSlowTransactionsLarge } from './large/performance/sst.model';
import { IPerformanceIssuesLarge } from './large/performance/pi.model';
import { ICrSmall } from './small/customization-vs-configuration/cr.model';
import { ICustomizationRatioLarge } from './large/customization-vs-configuration/cr.model';
import { IIntegrationsLarge } from './large/integrations/int.model';
import { IAtSmall } from './small/alerts/at.model';
import { IAiSmall } from './small/alerts/ai.model';
import { IAiLarge } from './large/alerts/ai.model';
import { IAtLarge } from './large/alerts/at.model';
import { IDuplicatedCodeSmall } from './small/duplicated-code/dc.model';
import { IDuplicatedCodeLarge } from './large/duplicated-code/dc.model';
import { IActiveUsersSFLarge } from './large/user-adoption-sf/au.model';
import { ITotalLoginsSFLarge } from './large/user-adoption-sf/tl.model';
import { ITotalLicensesSmall } from './small/cross-org-licensing/tl.model';
import { ITotalLicensesLarge } from './large/cross-org-licensing/tl.model';
import { IReleaseManagementSmall } from './small/release-management/rm.model';
import { IReleaseManagementLarge } from './large/release-management/rm.model';
import { IUsageGovernanceSnowSmall } from './small/governance-overview/usage-service-now.model';
import { IUsageGovernanceSFSmall } from './small/governance-overview/usage-salesforce.model';
import { IFeatureBranchLarge } from './large/feature-branch/fb.model';
import { IPeerReviewLarge } from './large/peer-review/pr.model';
import { IALarge } from './large/impact-analysis/ia/ia-large.model';
import { IWriteOffsLarge } from './large/write-offs/wo.model';
import { IDebtManagerLarge } from './large/debt-manager/dm.model';
import { IDebtManagerFixedLarge } from './large/debt-manager/dm-closed-fixed.model';
import { IDebtManagerWoLarge } from './large/debt-manager/dm-closed-wo.model';
import { ITimeMachineLarge } from './large/profiling/time-machin.model';

export interface IWidget<T> {
    isLoaded: boolean;
    loading: boolean;
    loadingWidgets?: boolean;
    loadingTable?: boolean;
    loadingFiltered?: boolean;
    error: string;
    filter: any;
    data: T;
    cascadeFilters?: any;
    cascadeBaseFilters?: any;
    tableFilter?: any;
}

export type FeatureState<T> = {
    [key: string]: T;
};

export enum chartViewType {
    'table' = 'table',
    'chart' = 'chart',
    'pie' = 'pie',
    'bar' = 'bar',
    'kanban' = 'kanban',
    'none' = 'none',
    'double-table' = 'double-table'
}

export interface StateFeatures {
    config: any;
    selectors: any;
    actions: any;
}

export const Widgets = {
    Large: {
        Overview: {
            USAGE: {} as IWidget<IUsageLarge>,
            ED: {} as IWidget<IEarlyDetectionLarge>
        },
        Usage: {
            UA: {} as IWidget<IUserAdoptionLarge>,
            LI: {} as IWidget<ILicenseLarge>,
            GLS: {} as IWidget<IGovernorLimitStorageLarge>,
            GLAC: {} as IWidget<IGovernorLimitApiCallsLarge>
        },
        Upgradeability: {
            OOTBINVENTORY: {} as IWidget<IOOTBInventoryUpgradeabilityLarge>
        },
        ImpactAnalysis: {
            IA: {} as IWidget<IALarge>
        },
        Governance: {
            SERVICENOW: {} as IWidget<IServiceNowLarge>,
            SALESFORCE: {} as IWidget<ISalesforceLarge>
        },
        AssetManager: {
            AM: {} as IWidget<IAssetManagerLarge>
        },
        Profiling: {
            APPS: {} as IWidget<IApplicationsLarge>,
            CT: {} as IWidget<ICustomTablesLarge>,
            ISS: {} as IWidget<IIssuesLarge>,
            LOCS: {} as IWidget<ILocsProfilingLarge>,
            NLOCS: {} as IWidget<INLocsProfilingLarge>,
            NCE: {} as IWidget<INewConfigurationElementsLarge>,
            WARN: {} as IWidget<IWarningsLarge>,
            CCTIR: {} as IWidget<ICodeChangesToIssuesRatioLarge>,
            TIME_MACHINE: {} as IWidget<ITimeMachineLarge>
        },
        CompareMultipleInstances: {
            CMI: {} as IWidget<ICMILarge>
        },
        MostConfigurableAreas: {
            MCA: {} as IWidget<IMostConfigurableAreasLarge>
        },
        UserAdoption: {
            AU: {} as IWidget<IActiveUsersLarge>,
            TL: {} as IWidget<ITotalLoginsLarge>,
            AUSF: {} as IWidget<IActiveUsersSFLarge>,
            TLSF: {} as IWidget<ITotalLoginsSFLarge>
        },
        Catalog: {
            NOC: {} as IWidget<INumberOfCatalogsLarge>,
            CC: {} as IWidget<ICatalogCategoriesLarge>,
            CV: {} as IWidget<ICatalogVariablesLarge>,
            CI: {} as IWidget<ICatalogItemsLarge>,
            ISSUES: {} as IWidget<ICatalogIssuesWarningLarge>
        },
        Licensing: {
            LI: {} as IWidget<ILicensesLarge>,
            GLS: {} as IWidget<IGlsLarge>,
            GLAC: {} as IWidget<IGlacLarge>
        },
        CrossOrgLicensing: {
            TL: {} as IWidget<ITotalLicensesLarge>
        },
        Performance: {
            CST: {} as IWidget<IClientSlowTransactionsLarge>,
            SST: {} as IWidget<IServerSlowTransactionsLarge>,
            PI: {} as IWidget<IPerformanceIssuesLarge>
        },
        CustomizationVsConfiguration: {
            CR: {} as IWidget<ICustomizationRatioLarge>
        },
        Integrations: {
            INT: {} as IWidget<IIntegrationsLarge>
        },
        Alerts: {
            AI: {} as IWidget<IAiLarge>,
            AT: {} as IWidget<IAtLarge>
        },
        DuplicatedCode: {
            DC: {} as IWidget<IDuplicatedCodeLarge>
        },
        ReleaseManagement: {
            RM: {} as IWidget<IReleaseManagementLarge>
        },
        FeatureBranch: {
            FB: {} as IWidget<IFeatureBranchLarge>
        },
        PeerReview: {
            PR: {} as IWidget<IPeerReviewLarge>
        },
        WriteOffs: {
            WO: {} as IWidget<IWriteOffsLarge>
        },
        DebtManager: {
            DM: {} as IWidget<IDebtManagerLarge>,
            DMCWO: {} as IWidget<IDebtManagerWoLarge>,
            DMCF: {} as IWidget<IDebtManagerFixedLarge>
        }
    },
    Small: {
        Overview: {
            USAGE: {} as IWidget<IUsageSmall>,
            ED: {} as IWidget<IEarlyDetectionSmall>,
            RMAPP: {} as IWidget<IRmAppSmall>,
            RMHIST: {} as IWidget<IRmHistSmall>,
            FB: {} as IWidget<IFbSmall>,
            SALESFORCE: {} as IWidget<ISalesforceSmall>,
            SERVICENOW: {} as IWidget<IServiceNowSmall>,
            AL: {} as IWidget<IAlertsSmall>
        },
        Usage: {
            UA: {} as IWidget<IUserAdoptionSmall>,
            LI: {} as IWidget<ILicenseSmall>,
            GLS: {} as IWidget<IGovernorLimitStorageSmall>,
            GLAC: {} as IWidget<IGovernorLimitApiCallsSmall>
        },
        Upgradeability: {
            CEU: {} as IWidget<IConfigurationElementsUpgradeabilitySmall>,
            OOTB: {} as IWidget<IOOTBUpgradeabilitySmall>,
            OOTBINVENTORY: {} as IWidget<IOOTBInventoryUpgradeabilitySmall>
        },
        Profiling: {
            CT: {} as IWidget<ICustomTablesSmall>,
            NLOCS: {} as IWidget<INewLocsSmall>,
            NCE: {} as IWidget<INewCeSmall>,
            WARN: {} as IWidget<IWarningsSmall>,
            APPS: {} as IWidget<ITotalApplicationsProfilingSmall>,
            TC: {} as IWidget<ITestCoverageSmall>,
            CCTIR: {} as IWidget<ICodeChangesIssuesRatioSmall>
        },
        CompareMultipleInstances: {
            INT: {} as IWidget<IIntegrationsSmall>
        },
        Catalog: {
            NOC: {} as IWidget<INocSmall>,
            CC: {} as IWidget<ICcSmall>,
            CV: {} as IWidget<ICvSmall>,
            CI: {} as IWidget<ICiSmall>,
            ISSUES: {} as IWidget<ICatalogIssuesSmall>
        },
        Performance: {
            CST: {} as IWidget<ICstSmall>,
            SST: {} as IWidget<ISstSmall>,
            PI: {} as IWidget<IPiSmall>
        },
        CustomizationVsConfiguration: {
            CR: {} as IWidget<ICrSmall>
        },
        UserAdoption: {
            AU: {} as IWidget<IAuSmall>,
            TL: {} as IWidget<ITlSmall>
        },
        Licensing: {
            LI: {} as IWidget<ILicensesSmall>,
            GLS: {} as IWidget<IGlsSmall>,
            GLAC: {} as IWidget<IGlacSmall>
        },
        CrossOrgLicensing: {
            TL: {} as IWidget<ITotalLicensesSmall>
        },
        Alerts: {
            AI: {} as IWidget<IAiSmall>,
            AT: {} as IWidget<IAtSmall>
        },
        DuplicatedCode: {
            DC: {} as IWidget<IDuplicatedCodeSmall>
        },
        ReleaseManagement: {
            RM: {} as IWidget<IReleaseManagementSmall>
        },
        Governance: {
            USF: {} as IWidget<IUsageGovernanceSFSmall>,
            USNOW: {} as IWidget<IUsageGovernanceSnowSmall>
        }
    }
};
