import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { Chart, ChartSizes } from '../../abstracts/chart.component';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexGrid,
    ApexPlotOptions,
    ApexStroke,
    ApexXAxis
} from 'ng-apexcharts';
import { DatePipe, DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';

@Component({
    selector: 'qcbi-bar-horizontal-chart',
    template: `
        <apx-chart
            #chart
            [autoUpdateSeries]="true"
            [chart]="chartConfig"
            [dataLabels]="dataLabels"
            [legend]="legend"
            [noData]="noData"
            [plotOptions]="overridePlotOptions || plotOptions"
            [responsive]="responsive"
            [series]="series"
            [states]="states"
            [stroke]="stroke"
            [subtitle]="subtitle"
            [theme]="theme"
            [title]="title"
            [tooltip]="tooltip"
            [xaxis]="xaxis"
        >
        </apx-chart>
    `,
    styleUrls: ['./charts.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarHorizontalChartComponent extends Chart {
    @Input() overridePlotOptions: ApexPlotOptions;
    @Output() dataSelected = new EventEmitter();

    sizes: ChartSizes = {
        small: {
            height: 150,
            width: '100%',
            responsive: []
        },
        medium: {
            height: 500,
            width: '100%',
            responsive: []
        },
        large: {
            height: 750,
            width: '100%',
            responsive: []
        }
    };

    chartConfig: ApexChart = {
        type: 'bar',
        toolbar: {
            offsetY: 15,
            offsetX: -15,
            tools: {
                pan: false
            }
        },
        fontFamily: 'Rubik, sans-serif',
        events: {
            dataPointSelection: (event, chartContext, config) => {
                this.dataSelected.emit(config);
            }
        }
    };

    plotOptions: ApexPlotOptions = {
        bar: {
            horizontal: true,
            dataLabels: {
                position: 'right',
                orientation: 'horizontal'
            }
        }
    };

    stroke: ApexStroke = {
        show: true,
        width: 1,
        colors: ['#fff']
    };
    grid: ApexGrid = {
        xaxis: {
            lines: {
                show: true
            }
        },
        yaxis: {
            lines: {
                show: false
            }
        },
        column: {
            colors: ['#F8F9FA', 'transparent']
        }
    };
    @Input() series: ApexAxisChartSeries;
    @Input() xaxis: ApexXAxis;
    constructor(
        public datepipe: DatePipe,
        public numberpipe: DecimalPipe,
        public translateService: TranslateService,
        public store: Store<AppState>
    ) {
        super(datepipe, store, numberpipe);
        this.dataLabels = {
            enabled: false,
            textAnchor: 'start',
            style: this.dataLabels.style
        };
    }
}
