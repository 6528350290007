import {
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    Output
} from '@angular/core';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface IAutoCompleteScrollEvent {
    autoComplete: MatAutocomplete;
    scrollEvent: Event;
}

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'mat-autocomplete[optionsScroll]'
})
export class OptionsScrollDirective implements OnDestroy {
    @Input() thresholdPercent = 0.99;
    // tslint:disable-next-line:no-output-rename
    @Output('optionsScroll') scroll =
        new EventEmitter<IAutoCompleteScrollEvent>();
    unsubscribe$ = new Subject();

    constructor(public autoComplete: MatAutocomplete) {
        this.autoComplete.opened
            .pipe(
                tap(() => {
                    setTimeout(() => {
                        this.removeScrollEventListener();
                        this.autoComplete.panel.nativeElement.addEventListener(
                            'scroll',
                            this.onScroll.bind(this)
                        );
                    });
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        this.autoComplete.closed
            .pipe(
                tap(() => this.removeScrollEventListener()),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.removeScrollEventListener();
    }

    onScroll(event: any) {
        if (this.thresholdPercent === undefined) {
            this.scroll.next({
                autoComplete: this.autoComplete,
                scrollEvent: event
            });
        } else {
            const threshold =
                (this.thresholdPercent * 100 * event.target.scrollHeight) / 100;
            const current = event.target.scrollTop + event.target.clientHeight;
            if (current > threshold) {
                this.scroll.next({
                    autoComplete: this.autoComplete,
                    scrollEvent: event
                });
            }
        }
    }

    private removeScrollEventListener() {
        if (
            this.autoComplete &&
            this.autoComplete.panel &&
            this.autoComplete.panel.nativeElement
        ) {
            this.autoComplete.panel.nativeElement.removeEventListener(
                'scroll',
                this.onScroll
            );
        }
    }
}
