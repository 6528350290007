import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { Chart, ChartSizes } from '../../abstracts/chart.component';
import {
    ApexChart,
    ApexPlotOptions,
    ApexStroke,
    ApexAxisChartSeries,
    ApexXAxis,
    ApexGrid
} from 'ng-apexcharts';
import { DatePipe, DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';

@Component({
    selector: 'qcbi-bar-vertical-chart',
    template: `
        <apx-chart
            #chart
            [autoUpdateSeries]="true"
            [chart]="chartConfig"
            [dataLabels]="dataLabels"
            [legend]="legend"
            [noData]="noData"
            [plotOptions]="overridePlotOptions || plotOptions"
            [responsive]="responsive"
            [series]="series"
            [states]="states"
            [stroke]="stroke"
            [theme]="theme"
            [title]="title"
            [tooltip]="tooltip"
            [xaxis]="_xaxis"
            [yaxis]="{
                logarithmic: logarithmic
            }"
        >
        </apx-chart>
    `,
    styleUrls: ['./charts.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarVerticalChartComponent extends Chart {
    @Input() overridePlotOptions: ApexPlotOptions;
    @Input() showDownload = true;
    @Input() rotateAlways = false;
    @Input() series: ApexAxisChartSeries;
    @Input() set xSeriesTitle(value: string) {
        this._xSeriesTitle = value;
        this._xaxis = {
            ...this._xaxis,
            title: {
                text: value
            }
        };
    }
    @Input() set xaxis(value: ApexXAxis) {
        this._xaxis = { ...this._xaxis, ...value };
        this._xaxis.labels = {
            show: this._xSeriesTitle === '',
            rotate: -80,
            rotateAlways: this.rotateAlways,
            hideOverlappingLabels: false,
            trim: true,
            minHeight: 150
        };
    }

    @Output() dataSelected = new EventEmitter();

    sizes: ChartSizes = {
        small: {
            height: 150,
            width: '100%',
            responsive: []
        },
        medium: {
            height: 500,
            width: '100%',
            responsive: []
        },
        large: {
            height: 800,
            width: '100%',
            responsive: []
        }
    };
    _xaxis: ApexXAxis = {};
    _xSeriesTitle = '';

    chartConfig: ApexChart = {
        type: 'bar',
        toolbar: {
            offsetY: 15,
            offsetX: -15,
            tools: {
                pan: false
            }
        },
        fontFamily: 'Rubik, sans-serif',
        events: {
            dataPointSelection: (event, chartContext, config) => {
                this.dataSelected.emit(config);
            }
        }
    };

    selectedValue(event, chartContext, config) {
        console.log('barchart data', chartContext, config);
    }

    plotOptions: ApexPlotOptions = {
        bar: {
            dataLabels: {
                position: 'top',
                orientation: 'vertical'
            }
        }
    };

    stroke: ApexStroke = {
        show: true,
        width: 1,
        colors: ['#fff']
    };
    grid: ApexGrid = {
        xaxis: {
            lines: {
                show: true
            }
        },
        yaxis: {
            lines: {
                show: false
            }
        },
        column: {
            colors: ['#F8F9FA', 'transparent']
        }
    };

    constructor(
        public datepipe: DatePipe,
        public numberpipe: DecimalPipe,
        public translateService: TranslateService,
        public store: Store<AppState>
    ) {
        super(datepipe, store, numberpipe, translateService);
        this.dataLabels = {
            enabled: false,
            textAnchor: 'start',
            style: this.dataLabels.style
        };
    }
}
