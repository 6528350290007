import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import {
    ChartComponent,
    ApexDataLabels,
    ApexAxisChartSeries,
    ApexResponsive,
    ApexXAxis,
    ApexYAxis
} from 'ng-apexcharts';

@Component({
    selector: 'qc-chart',
    templateUrl: './qc-chart.component.html'
})
export class QcChartComponent implements OnInit {
    @ViewChild('chart', { static: false }) chart: ChartComponent;
    stroke: {};
    chartConfig: {};
    fill: {};
    colors: [];
    grid: {};
    plotOptions: {};
    dataLabels = {};
    responsive: ApexResponsive[] = [
        {
            breakpoint: 600,
            options: {
                width: 300
            }
        },
        {
            breakpoint: 900,
            options: {
                width: 450
            }
        },
        {
            breakpoint: 1200,
            options: {
                width: 600
            }
        },
        {
            breakpoint: 1500,
            options: {
                width: 750
            }
        }
    ];
    legend: {};
    theme = {};

    @Input() type: string;
    @Input() series: number[] | ApexAxisChartSeries = [];
    @Input() xaxis: ApexXAxis = { categories: [] };
    @Input() labels: string[] = [];

    @Input() yaxis: ApexYAxis | ApexYAxis[] = {};

    @Input() formatDataLabels: boolean = false;

    @Output() markerClick = new EventEmitter();
    @Output() dataClick = new EventEmitter();
    // @Output() legendClick = new EventEmitter();

    constructor() {}

    ngOnInit(): void {
        const self = this;
        const conf = require('../../../assets/charts/' + this.type + '.json');
        this.stroke = conf.stroke;
        this.chartConfig = {
            ...conf.chart,
            events: {
                markerClick: (
                    event,
                    chartContext,
                    { seriesIndex, dataPointIndex }
                ) => {
                    if (this.markerClick.observers.length > 0)
                        this.markerClick.emit({ seriesIndex, dataPointIndex });
                },
                dataPointSelection: (event, chartContext, config) => {
                    if (self.dataClick.observers.length > 0)
                        self.dataClick.emit({ event, chartContext, config });
                }
            }
        };
        this.fill = conf.fill;
        this.dataLabels = conf.dataLabels || {};
        if (this.formatDataLabels) {
            this.dataLabels['formatter'] = function(val, opt) {
                return console.log(opt.w.globals.labels[opt.seriesIndex]);
            };
        }
        this.colors = conf.colors;
        this.grid = conf.grid;
        this.plotOptions = conf.plotOptions;
        this.legend = conf.legend || {};
        this.theme = conf.theme || {};
    }
}
