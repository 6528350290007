import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/core.state';
import { ViewNameValidator } from './view-name-validator';
import { Observable, Subject } from 'rxjs';
import { selectCurrentUser } from '../../../../../../core/auth-lib';
import { map, takeUntil } from 'rxjs/operators';
import { ROLES } from '../../../../../../../utils/constants';
import { User } from './components/users-dropdown-search/users-dropdown-search.component';
import { viewsSelectors } from '../../../../../../core/state/views/debt-manager-new/dm-new-view.selectors';

export type FormViewData = {
    name: string;
    description: string;
    isPrivate: boolean;
    sharedWith?: number[];
    userId?: number;
};

const MESSAGES = {
    private: 'View is only visible to you',
    public:
        'View visible to all users with access to the Debt Manager dashboard',
    shared: 'View visible to selected users only'
};

@Component({
    selector: 'qcbi-view-form',
    templateUrl: 'view-form.component.html',
    styleUrls: ['./view-form.component.scss']
})
export class ViewFormComponent implements OnInit, OnDestroy {
    @Input() submitting = false;
    @Input() data: FormViewData;
    @Input() actionLabel = 'Create';
    @Input() mainText: string;

    @Output() submitForm = new EventEmitter<FormViewData>();
    @Output() cancel = new EventEmitter();

    myForm: UntypedFormGroup;
    selectedUsers: User[] = [];
    initialIds: number[];

    canViewVisualizationDetails$: Observable<boolean>;
    restrictEditOptions$: Observable<boolean>;
    maxPrivateViewsReached$: Observable<boolean>;
    maxPublicViewsReached$: Observable<boolean>;

    private unsubscribe$ = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.myForm = this.fb.group({
            name: new UntypedFormControl(
                '',
                [Validators.required, Validators.maxLength(40)],
                ViewNameValidator.createValidator(this.store, this.data?.name)
            ),
            description: new UntypedFormControl('', [
                Validators.maxLength(255)
            ]),
            sharedWith: new UntypedFormControl('', [Validators.required])
        });
        this.restrictEditOptions$ = this.store
            .select(selectCurrentUser)
            .pipe(map(me => this.data && +this.data.userId !== +me.id));
        this.canViewVisualizationDetails$ = this.store
            .select(selectCurrentUser)
            .pipe(
                map(me => {
                    const show =
                        me.role === ROLES.QC_ADMIN ||
                        me.role === ROLES.CUSTOMER_ADMIN;
                    if (!show) {
                        this.myForm.removeControl('sharedWith');
                    }
                    return show;
                })
            );
        this.myForm.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ sharedWith }) => {
                if (sharedWith !== 'shared') {
                    this.selectedUsers = [];
                    this.initialIds = [];
                }
            });
        this.maxPrivateViewsReached$ = this.store.select(
            viewsSelectors.maxPrivateViewsReached
        );
        this.maxPublicViewsReached$ = this.store.select(
            viewsSelectors.maxSharedViewsReached
        );
        if (this.data) {
            const { sharedWith, name, description, isPrivate } = this.data;
            let sharedWithValue = 'private';
            if (!isPrivate) {
                if (sharedWith && sharedWith.length) sharedWithValue = 'shared';
                else sharedWithValue = 'public';
            }

            if (sharedWith?.length) {
                this.initialIds = sharedWith;
                this.selectedUsers = sharedWith.map(id => {
                    return {
                        id,
                        username: ''
                    };
                });
            }
            this.myForm.patchValue({
                name,
                description: description || '',
                sharedWith: sharedWithValue
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    selectedUsersChangeHandler(newSelectedUsers: User[]) {
        this.selectedUsers = newSelectedUsers;
    }

    createView() {
        this.submitForm.emit({
            name: this.myForm.value.name,
            description: this.myForm.value.description,
            isPrivate:
                this.myForm.value.sharedWith !== 'public' &&
                this.myForm.value.sharedWith !== 'shared',
            sharedWith: this.selectedUsers.map(u => u.id)
        });
    }

    cancelForm() {
        this.cancel.emit();
    }

    getInfo() {
        if (!MESSAGES.hasOwnProperty(this.myForm.value.sharedWith)) return '';
        return MESSAGES[this.myForm.value.sharedWith];
    }

    showUpdateInfo(): boolean {
        return (
            this.data &&
            (!this.data.isPrivate ||
                (this.myForm.value.sharedWith &&
                    this.myForm.value.sharedWith !== 'private'))
        );
    }
}
