import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'qcbi-change-smart-tag-dialog',
    templateUrl: 'change-smart-tag-dialog.component.html',
    styleUrls: ['change-smart-tag-dialog.component.scss']
})
export class ChangeSmartTagDialogComponent {
    smartTag = 'legacy';

    constructor(
        public dialogRef: MatDialogRef<ChangeSmartTagDialogComponent>
    ) {}

    submitForm(value: any) {
        this.dialogRef.close(value.carrousel);
    }

    close() {
        this.dialogRef.close();
    }
}
