import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'qcbi-download',
    templateUrl: './download.component.html',
    styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
    static readonly VALID_DOWNLOAD_TYPES = ['issue', 'audit-element', 'report'];

    status: 'DOWNLOADING' | 'ERROR' | 'PARAMS' | 'DOWNLOADED' = 'DOWNLOADING';

    constructor(
        private activatedRoute: ActivatedRoute,
        private http: HttpClient
    ) {}

    ngOnInit() {
        const type: string = this.activatedRoute.parent.snapshot?.params.type;
        const scanId: string = this.activatedRoute.snapshot?.params.scanId;
        if (
            type === undefined ||
            scanId === undefined ||
            !DownloadComponent.VALID_DOWNLOAD_TYPES.includes(type)
        ) {
            this.status = 'PARAMS';
            return;
        }
        if (type === 'report') {
            this.downloadReport(scanId);
        } else {
            this.downloadXLS(type, scanId);
        }
    }

    /**
     * returns the icon depending on the status
     */
    getIcon(): string {
        if (this.status === 'ERROR' || this.status === 'PARAMS')
            return 'warning';
        return 'done';
    }

    /**
     * returns the current download process text
     */
    getText(): string {
        switch (this.status) {
            case 'PARAMS':
                return 'Wrong params';
            case 'DOWNLOADED':
                return 'Downloaded';
            case 'ERROR':
                return 'Download error';
            default:
            case 'DOWNLOADING':
                return 'Downloading...';
        }
    }

    /**
     * Downloads the file from data
     * @param data data to create and download the file from
     * @param type
     * @param name
     */
    private downloadFile(
        data: ArrayBuffer | Blob,
        type: 'XLSX' | 'PDF',
        name: string
    ) {
        const blob: Blob = new Blob([data], { type: 'data:attachment/xlsx' });
        const link: HTMLAnchorElement = window.document.createElement('a');
        const defaultName: string = link.href.substr(
            link.href.lastIndexOf('/') + 1
        );
        link.href = window.URL.createObjectURL(blob);
        link.download = `${name || defaultName}.${type.toLowerCase()}`;
        link.dispatchEvent(new MouseEvent('click'));
        link.remove();
        URL.revokeObjectURL(link.href);
    }

    /**
     * Downloads the report from a specific scan
     * @param scanId scan to download the report from
     */
    private downloadReport(scanId) {
        const formData: FormData = new FormData();
        const url = `${environment.apiEndPoint}reports/v1/profiling`;
        formData.append('scan_id', scanId);
        formData.append('pdf_title', 'profiling_report');
        formData.append('notes', '');
        this.http
            .post(url, formData, {
                headers: {
                    'is-multipart': 'true'
                },
                responseType: 'arraybuffer'
            })
            .subscribe(
                data => {
                    this.downloadFile(data, 'PDF', 'profiling_report');
                    this.status = 'DOWNLOADED';
                },
                err => {
                    console.error(err);
                    this.status = 'ERROR';
                }
            );
    }

    /**
     * Downloads the xls document
     * @param type specifies if the document is an issue or a config element xls
     * @param scanId scan from the data
     */
    private downloadXLS(type: string, scanId: string) {
        const url: string =
            type === 'issue'
                ? `${environment.apiUrl}/v2/issues-xls-file/${scanId}`
                : `${environment.apiUrl}/v2/audit-elements-xls-file/${scanId}`;

        this.http.get(url, { responseType: 'blob' }).subscribe(
            res => {
                this.downloadFile(res, 'XLSX', undefined);
                this.status = 'DOWNLOADED';
            },
            error => {
                console.error(error);
                this.status = 'ERROR';
            }
        );
    }
}
