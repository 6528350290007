import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../../core.state';
import { StateConfig } from './dm-new-view.config';
import {
    DebtManagerView,
    FeatureState,
    OwnedView,
    State,
    Status
} from './dm-new-view.reducer';

export const MAX_OPENED_VIEWS = 14;
const MAX_PRIVATE_VIEWS = 5;
const MAX_PUBLIC_VIEWS = 5;

const stateSelector = createFeatureSelector<AppState & FeatureState, State>(
    StateConfig.Key
);

export const viewsSelectors = {
    availableViews: createSelector(
        stateSelector,
        (state: State) => state.availableViews
    ),
    allAvailableViews: createSelector(stateSelector, (state: State) => [
        ...state.availableViews.myViews,
        ...state.availableViews.default,
        ...state.availableViews.sharedViews
    ]),
    openedViews: createSelector(
        stateSelector,
        (state: State) => state.openedViews
    ),
    saving: createSelector(
        stateSelector,
        (state: State) => state.status === 'SAVING'
    ),
    viewsCreationAbility: createSelector(stateSelector, (state: State) =>
        [
            ...state.availableViews.myViews,
            ...state.availableViews.default,
            ...state.availableViews.sharedViews
        ].reduce(
            (result, next: DebtManagerView | OwnedView) => {
                result[next.id] = !(
                    next.hasOwnProperty('userId') &&
                    (next as OwnedView).userId !== undefined &&
                    (next as OwnedView).userId !== null
                );
                return result;
            },
            {
                home: true
            }
        )
    ),
    viewFromId: (viewId: string) =>
        createSelector(stateSelector, (state: State) => {
            if (!state) return undefined;
            return [
                ...state.availableViews.myViews,
                ...state.availableViews.default,
                ...state.availableViews.sharedViews
            ].find(v => v.id === viewId);
        }),
    maxOpenedViewsReached: createSelector(
        stateSelector,
        (state: State) => state.openedViews.length === MAX_OPENED_VIEWS
    ),
    maxPrivateViewsReached: createSelector(
        stateSelector,
        (state: State) =>
            state.availableViews.myViews.length === MAX_PRIVATE_VIEWS
    ),
    maxSharedViewsReached: createSelector(
        stateSelector,
        (state: State) =>
            state.availableViews.sharedViews.length === MAX_PUBLIC_VIEWS
    ),
    loadingSavedViews: createSelector(
        stateSelector,
        (state: State) =>
            state.availableViews.default.length === 0 &&
            state.status !== 'ERROR LOADING VIEWS'
    ),
    deleting: createSelector(
        stateSelector,
        (state: State) => state.status === Status.DELETING
    ),
    updatingFilters: createSelector(
        stateSelector,
        (state: State) => state.status === Status.UPDATING_FILTERS
    ),
    errorLoadingViews: createSelector(
        stateSelector,
        (state: State) => state.status === 'ERROR LOADING VIEWS'
    )
};
