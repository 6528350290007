import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexGrid,
    ApexPlotOptions,
    ApexStroke,
    ApexXAxis,
    ApexYAxis
} from 'ng-apexcharts';
import { Chart } from '../../abstracts/chart.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';

@Component({
    selector: 'qcbi-bar-compact-chart',
    template: `
        <apx-chart
            #chart
            [annotations]="chartAnnotations"
            [autoUpdateSeries]="true"
            [chart]="chartConfig"
            [dataLabels]="dataLabels"
            [noData]="noData"
            [plotOptions]="plotOptions"
            [series]="series"
            [states]="states"
            [stroke]="stroke"
            [theme]="theme"
            [tooltip]="tooltip"
            [xaxis]="_xaxis"
            [yaxis]="_yaxis"
        >
        </apx-chart>
    `,
    styleUrls: ['./charts.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarCompactChartComponent extends Chart {
    sizes = {
        small: {
            height: 30,
            width: '100%',
            responsive: []
        },
        medium: {
            height: 500,
            width: '100%',
            responsive: []
        },
        large: {
            height: 600,
            width: '100%',
            responsive: []
        }
    };

    chartConfig: ApexChart = {
        height: 50,
        type: 'bar',
        toolbar: {
            show: false
        },
        fontFamily: 'Rubik, sans-serif',
        width: '100%',
        zoom: {
            enabled: false
        },
        sparkline: {
            enabled: true
        },
        animations: {
            enabled: false
        }
    };

    dataLabels: ApexDataLabels = {
        enabled: false
    };

    grid: ApexGrid = {
        show: false,
        yaxis: {
            lines: {
                show: false
            }
        },
        xaxis: {
            lines: {
                show: false
            }
        },
        row: {
            opacity: 0
        }
    };

    plotOptions: ApexPlotOptions = {
        bar: {
            columnWidth: '30%'
        }
    };

    stroke: ApexStroke = {
        width: 2,
        lineCap: 'round'
    };

    @Input() series: ApexAxisChartSeries;
    _xaxis: ApexXAxis;
    _yaxis: ApexYAxis = {
        show: false,
        labels: {
            show: false
        }
    };

    @Input()
    set xaxis(value: ApexXAxis) {
        if (!value) return;
        this._xaxis = {
            ...value,
            type: 'category',
            labels: {
                show: false
            },
            tooltip: {
                enabled: false
            },
            axisBorder: {
                show: false
            }
        };
    }
    constructor(
        public datepipe: DatePipe,
        public numberpipe: DecimalPipe,
        public store: Store<AppState>
    ) {
        super(datepipe, store, numberpipe);
    }
}
