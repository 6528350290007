import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    OnDestroy
} from '@angular/core';
import idleService, { IdleEvents } from '@kurtz1993/idle-service';
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { qcAuthActions } from '../../actions';

idleService.configure({
    timeToIdle: 15 * 60,
    timeToTimeout: 60,
    autoResume: true,
    listenFor: 'click mousemove'
});

@Component({
    selector: 'qcauth-dialog',
    template: `
        <div class="dialog">
            <div class="row justify-content-center position-relative">
                <img alt="" src="./assets/images/logoInverted.png" />
            </div>
            <div class="row justify-content-center position-relative m-3">
                <h5 style="font-weight:400; font-size:16px; margin:0;">
                    Your session is about to expire due to inactivity.
                </h5>
            </div>
            <div class="row justify-content-center position-relative m-3">
                <h5 style="font-weight:400; font-size:16px; margin:0;">
                    {{ countdown }}
                </h5>
            </div>
        </div>
    `
})
export class QCAuthIdleDialogComponent {
    public countdown: string;
    constructor() {}
}

@Component({
    selector: 'qcauth-idle',
    template: '<div id="qc-idle"></div>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QCAuthIdleComponent implements OnInit, OnDestroy {
    dialogComponent: MatDialogRef<QCAuthIdleDialogComponent>;
    countdown = new Subject<string>();
    $countdown = this.countdown.asObservable();
    isOpened = false;
    constructor(private dialog: MatDialog, private store: Store<any>) {}

    ngOnInit() {
        idleService.start();
        idleService.on(IdleEvents.UserIsBack, () => {
            if (this.isOpened) {
                this.dialogComponent.close();
                idleService.reset();
            }
        });
        idleService.on(IdleEvents.UserHasTimedOut, () => {
            this.dialogComponent.componentInstance.countdown =
                this.getMessage(0);
            this.store.dispatch(qcAuthActions.logoutOnFocus());
        });
        idleService.on(IdleEvents.TimeoutWarning, (countdown: number) => {
            this.dialogComponent.componentInstance.countdown =
                this.getMessage(countdown);
            if (!this.isOpened) this.openDialog();
        });
        idleService.on(IdleEvents.UserIsIdle, () => {
            if (!this.isOpened) this.openDialog();
        });
    }

    ngOnDestroy(): void {
        if (this.isOpened) this.dialogComponent.close();
        idleService.stop();
    }

    openDialog() {
        if (this.isOpened) return;
        this.isOpened = true;
        this.dialogComponent = this.dialog.open(QCAuthIdleDialogComponent, {
            width: '600px'
        });
        this.dialogComponent.componentInstance.countdown = this.getMessage(60);
        this.dialogComponent.afterClosed().subscribe((result) => {
            this.isOpened = false;
        });
    }

    getMessage(seconds: number) {
        return 'You will be logged out in ' + seconds + ' seconds';
    }
}
