import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    ApexChart,
    ApexFill,
    ApexNonAxisChartSeries,
    ApexPlotOptions,
    ApexStroke
} from 'ng-apexcharts';
import { DatePipe } from '@angular/common';
import { Chart } from '../../abstracts/chart.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';

@Component({
    selector: 'qcbi-gauge-chart',
    template: `
        <apx-chart
            #chart
            [chart]="chartConfig"
            [series]="series"
            [labels]="labels"
            [legend]="legend"
            [tooltip]="tooltip"
            [noData]="noData"
            [states]="states"
            [stroke]="stroke"
            [fill]="fill"
            [colors]="colors"
            [plotOptions]="plotOptions"
            autoUpdateSeries="true"
        >
        </apx-chart>
    `,
    styleUrls: ['./charts.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GaugeChartComponent extends Chart {
    sizes = {
        small: {
            height: 150,
            width: 150,
            responsive: []
        },
        medium: {
            height: 500,
            width: 450,
            responsive: []
        },
        large: {
            height: 750,
            width: 750,
            responsive: []
        }
    };

    chartConfig: ApexChart = {
        type: 'radialBar',
        sparkline: {
            enabled: true
        },
        // offsetY: -20,
        height: 150,
        width: 150,
        fontFamily: 'Rubik, sans-serif'
    };

    plotOptions: ApexPlotOptions = {
        radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
                size: '45%'
            },
            dataLabels: {
                name: {
                    show: false
                },
                value: {
                    fontFamily: 'Rubik, sans-serif',
                    fontSize: '18px',
                    offsetY: 7
                }
            }
        }
    };

    stroke: ApexStroke = {
        lineCap: 'round'
    };

    fill: ApexFill = {
        type: 'image',
        image: {
            src: ['./assets/images/radial.png']
        }
    };

    colors = ['#FA8A8A'];

    @Input() series: ApexNonAxisChartSeries;
    @Input() labels: string[];
    constructor(public datepipe: DatePipe, public store: Store<AppState>) {
        super(datepipe, store);
    }
}
