import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError, first, map } from 'rxjs/operators';
import {
    DefaultSettings,
    InitialLoadSuccessData,
    InstanceLoadSuccessData,
    ScanLoadSuccessData,
    SimpleDefaultSettings,
    SimpleInstanceLoadSuccessData
} from './general.models';
import {
    getServicePath,
    getYYYYMMDDfromDate,
    parseDashboardContext,
    sortByDate,
    sortByLabel
} from '../../../utils/general';
import { Router } from '@angular/router';
import { DashboardService } from '../../services/dashboard.service';
import { InstanceService } from '../../services/instance.service';
import {
    ALL_CLOUDS,
    OFFICE_ID,
    SALESFORCE_ID,
    SERVICENOW_ID
} from '../../../../utils/constants';
import { ScanService } from '../../services/scan.service';
import { UsersService } from '../../services/users.service';

@Injectable({
    providedIn: 'root'
})
export class GeneralService {
    constructor(
        private http: HttpClient,
        private router: Router,
        private dashboardService: DashboardService,
        private instanceService: InstanceService,
        private scanService: ScanService,
        private userService: UsersService
    ) {}

    instanceScansAndDashboardLoad(
        defaultSettings: DefaultSettings
    ): Observable<InstanceLoadSuccessData> {
        const { serviceId, instanceId, dashboardId } = defaultSettings;
        return combineLatest([
            this.instanceService.getInstance(
                instanceId,
                getServicePath(serviceId)
            ),
            this.scanService.getScansByInstance(instanceId),
            this.scanService.getProfilingScansByInstance(instanceId),
            this.dashboardService.getDashboards(instanceId)
        ]).pipe(
            map(([instance, scans, profilingScans, dashboards]) => {
                const scan = scans.length ? scans[scans.length - 1] : null;
                const profilingScan = profilingScans.length
                    ? profilingScans[profilingScans.length - 1]
                    : null;
                const urlSplit = this.router.url.split('/');
                let dashboard = dashboards.find(
                    (item: any) =>
                        urlSplit.length >= 4 && item.url === urlSplit[3]
                );
                if (!dashboard) {
                    dashboard = dashboards.find(
                        (item: any) => item.id === dashboardId
                    );
                }
                if (!dashboard) {
                    dashboard = dashboards[0];
                }

                return {
                    instance,
                    scans,
                    profilingScans,
                    dashboards,
                    dashboard,
                    scan:
                        dashboard.url === 'profiling' && profilingScan !== null
                            ? {
                                  ...profilingScan,
                                  ['short-id']: profilingScan.value
                              }
                            : scan !== null
                            ? {
                                  ...scan,
                                  ['short-id']: scan.value
                              }
                            : null,
                    filters: undefined
                };
            })
        );
    }

    simpleInstanceAndScansLoad({
        serviceId,
        instanceId
    }: SimpleDefaultSettings): Observable<SimpleInstanceLoadSuccessData> {
        return forkJoin([
            this.instanceService.getInstance(
                instanceId,
                getServicePath(serviceId)
            ),
            this.scanService.getScansByInstance(instanceId)
        ]).pipe(
            map(([instance, scans]) => ({
                instance,
                scans,
                scan: scans.length
                    ? { ...scans[0], 'short-id': scans[0].value }
                    : undefined
            }))
        );
    }

    getScanAndFiltersInfo(
        serviceId: number,
        scanId: number,
        instanceId: any
    ): Observable<any> {
        if (serviceId === 99)
            return combineLatest([
                this.scanService.getScan(scanId).pipe(first()),
                of({})
            ]);
        return combineLatest([
            this.scanService.getScan(scanId).pipe(first()),
            this.getFilters(serviceId, scanId, instanceId).pipe(first())
        ]);
    }

    scanAndFiltersLoad(
        serviceId: number,
        scanId: number,
        instanceId: any,
        avoidFiltersCall: boolean = false
    ): Observable<ScanLoadSuccessData> {
        return combineLatest([
            this.scanService.getScan(scanId),
            avoidFiltersCall
                ? of({})
                : this.getFilters(serviceId, scanId, instanceId)
        ]).pipe(
            map(([scan, filters]) => ({
                scan,
                filters
            }))
        );
    }

    getVersions(): Observable<any> {
        const url = `${environment.apiEndPoint}api/v1/snow/ootb/releases?page%5Btotals%5D&sort=-gaDate`;
        return this.http.get(url);
    }

    getDateFilters(range, instanceId, serviceId) {
        const url = environment.apiEndPoint;
        let providerId: number;
        if (this.router.url.includes('servicenow')) {
            providerId = SERVICENOW_ID;
        } else if (this.router.url.includes('salesforce')) {
            providerId = SALESFORCE_ID;
        }
        if (range === null || range.being === null || range.end === null)
            return of({
                dateCeTypes: {},
                dateUsers: {},
                dateCeNames: {},
                dateIssuesCeTypes: {},
                dateIssuesDevelopers: {},
                dateUpdateSetQgStatus: {},
                dateUpdateSetStatus: {},
                dateUpdateSetReleaseType: {},
                dateScansCeTypes: {},
                dateScansDevelopers: {},
                dateScansQualityGatesResults: {},
                dateFeatureBranchQgStatus: {},
                dateFeatureBranchStatus: {},
                dateFeatureBranchReleaseType: {},
                dateAppInventoryQgStatus: {},
                dateAppInventoryStatus: {},
                dateUpdateSetName: {},
                dateFeatureBranchName: {},
                dateTeams: {},
                dateIssuesTeams: {},
                generalFiltersLoaded: false
            });
        return forkJoin([
            this.getDateCeTypes(url, instanceId, providerId, range),
            this.getDateUsers(url, instanceId, providerId, range),
            this.getDateCeNames(url, instanceId, providerId, range),
            this.getDateIssuesCeTypes(url, instanceId, providerId, range),
            this.getDateIssuesDevelopers(url, instanceId, providerId, range),
            this.getDateUpdateSetQgStatus(url, instanceId, serviceId, range),
            this.getDateUpdateSetStatus(url, instanceId, serviceId, range),
            this.getDateUpdateSetReleaseType(url, instanceId, serviceId, range),
            this.getDateScansCeTypes(url, instanceId, providerId, range),
            this.getDateScansDevelopers(url, instanceId, providerId, range),
            this.getDateScansQualityGatesResults(
                url,
                instanceId,
                providerId,
                range
            ),
            this.getDateFeatureBranchQgStatus(
                url,
                instanceId,
                serviceId,
                range
            ),
            this.getDateFeatureBranchStatus(url, instanceId, serviceId, range),
            this.getDateFeatureBranchReleaseType(
                url,
                instanceId,
                serviceId,
                range
            ),
            this.getDateAppInventoryQgStatus(url, instanceId, serviceId, range),
            this.getDateAppInventoryStatus(url, instanceId, serviceId, range),
            this.getDateUpdateSetName(url, instanceId, serviceId, range),
            this.getDateFeatureBranchName(url, instanceId, serviceId, range),
            this.getDateTeams(url, instanceId, range),
            this.getDateIssuesTeams(url, instanceId, range)
        ]).pipe(
            map((res) => res.map((el: any) => el.data)),
            map(
                ([
                    dateCeTypes,
                    dateUsers,
                    dateCeNames,
                    dateIssuesCeTypes,
                    dateIssuesDevelopers,
                    dateUpdateSetQgStatus,
                    dateUpdateSetStatus,
                    dateUpdateSetReleaseType,
                    dateScansCeTypes,
                    dateScansDevelopers,
                    dateScansQualityGatesResults,
                    dateFeatureBranchQgStatus,
                    dateFeatureBranchStatus,
                    dateFeatureBranchReleaseType,
                    dateAppInventoryQgStatus,
                    dateAppInventoryStatus,
                    dateUpdateSetName,
                    dateFeatureBranchName,
                    dateTeams,
                    dateIssuesTeams
                ]) => ({
                    dateCeTypes,
                    dateUsers,
                    dateCeNames,
                    dateIssuesCeTypes,
                    dateIssuesDevelopers,
                    dateUpdateSetQgStatus,
                    dateUpdateSetStatus,
                    dateUpdateSetReleaseType,
                    dateScansCeTypes,
                    dateScansDevelopers,
                    dateScansQualityGatesResults,
                    dateFeatureBranchQgStatus,
                    dateFeatureBranchStatus,
                    dateFeatureBranchReleaseType,
                    dateAppInventoryQgStatus,
                    dateAppInventoryStatus,
                    dateUpdateSetName,
                    dateFeatureBranchName,
                    dateTeams,
                    dateIssuesTeams
                })
            )
        );
    }

    getFilters(
        serviceId: number,
        scanId: number,
        instanceId: any
    ): Observable<{
        createdBy: any[];
        updatedBy: any[];
        auditUpdatedBy: any[];
        updatedByUgr: any[];
        cetypesnamesUgr: any[];
        areas: any[];
        bestPractices: any[];
        severities: any[];
        namespaces: any[];
        cmNamespaces: any[];
        cetypes: any[];
        cetypesnames: any[];
        licensesCustomer: any[];
        orgs: any[];
        sources: any[];
        auditElement: any[];
        auditApplication: any[];
        scansFilter: any[];
        typeOfChange: any;
        ootbModifications: any;
        cetypesnamesisootb: any[];
        CInamespaces: any[];
    }> {
        const url = environment.apiEndPoint;
        if (scanId === null) {
            return forkJoin([
                this.http
                    .get(url + 'widgets/v1/licenses-in-customer-filter')
                    .pipe(catchError(() => of({ data: [] }))),
                this.http
                    .get(url + 'widgets/v1/orgs-available-filter?providerId=2')
                    .pipe(catchError(() => of({ data: [] })))
            ]).pipe(
                map((res) => res.map((el: any) => el.data)),
                map(([licensesCustomerData, orgsData]) => ({
                    createdBy: [],
                    updatedBy: [],
                    auditUpdatedBy: [],
                    updatedByUgr: [],
                    cetypesnamesUgr: [],
                    areas: [],
                    bestPractices: [],
                    severities: [],
                    namespaces: [],
                    cmNamespaces: [],
                    cetypes: [],
                    cetypesnames: [],
                    licensesCustomer: licensesCustomerData
                        .map((elem: { label: string; value: string }) => {
                            const newElem = { ...elem };
                            newElem.value = newElem.label;
                            return newElem;
                        })
                        .sort(sortByLabel),
                    orgs: orgsData.sort(sortByLabel),
                    sources: [],
                    auditElement: [],
                    auditApplication: [],
                    scansFilter: [],
                    typeOfChange: [],
                    ootbModifications: [],
                    cetypesnamesisootb: [],
                    CInamespaces: []
                }))
            );
        }
        return forkJoin([
            this.http.get(url + 'widgets/v1/updated-by/issues/' + scanId),
            this.http.get(url + 'widgets/v1/areas/' + scanId),
            this.http.get(url + 'widgets/v1/best-practices/' + scanId),
            this.http.get(url + 'widgets/v1/severities/' + scanId),
            serviceId !== OFFICE_ID
                ? this.http.get(url + 'widgets/v1/namespaces/issues/' + scanId)
                : of({ data: [] }),
            this.http
                .get(
                    environment.apiDevEndPoint +
                        'api/v2/ce-type?filter[service_id]=' +
                        serviceId
                )
                .pipe(
                    map((data: any) =>
                        data.data.map((item) => ({
                            label: item.attributes.name,
                            value: item.id
                        }))
                    )
                ),
            serviceId !== OFFICE_ID
                ? this.http.get(url + 'widgets/v1/sources/' + scanId)
                : of({ data: [] }),
            serviceId !== OFFICE_ID
                ? this.http.get(
                      url + 'widgets/v1/namespaces/audit-element/' + scanId
                  )
                : of({ data: [] }),
            this.http.get(
                url + 'widgets/v1/created-by/audit-element/' + scanId
            ),
            instanceId
                ? this.http.get(
                      url +
                          `widgets/v1/scans/${instanceId}?isProfiling=false&without_warnings=true`
                  )
                : of({ data: [] }),
            this.http.get(
                url +
                    'widgets/v1/type-of-change/' +
                    scanId +
                    '?onlyChanges=true'
            ),
            serviceId !== OFFICE_ID
                ? this.http.get(
                      url + 'widgets/v1/ootb-modifications-filter/' + scanId
                  )
                : of({ data: [] }),
            this.http.get(
                url +
                    'widgets/v1/developers/audit-elements/' +
                    scanId +
                    '?isOotb=true'
            ),
            this.http.get(url + 'widgets/v1/ce-types/audit-element/' + scanId),
            this.http
                .get(url + 'widgets/v1/licenses-in-customer-filter')
                .pipe(catchError(() => of({ data: [] }))),
            this.http
                .get(url + 'widgets/v1/orgs-available-filter?providerId=2')
                .pipe(catchError(() => of({ data: [] }))),
            serviceId !== OFFICE_ID
                ? this.http.get(
                      url +
                          'widgets/v1/namespaces/issues/' +
                          scanId +
                          '?codeMonitor=true'
                  )
                : of({ data: [] }),
            serviceId !== OFFICE_ID
                ? this.http.get(
                      url +
                          'widgets/v1/developers/audit-elements/' +
                          scanId +
                          '?isAffectedUgr=true'
                  )
                : of({ data: [] }),
            serviceId !== OFFICE_ID
                ? this.http.get(
                      url +
                          'widgets/v1/ce-types/audit-element/' +
                          scanId +
                          '?isAffectedUgr=true'
                  )
                : of({ data: [] }),
            serviceId !== OFFICE_ID
                ? this.http.get(
                      url +
                          'widgets/v1/ce-types/audit-element/' +
                          scanId +
                          '?isOotb=true'
                  )
                : of({ data: [] })
        ]).pipe(
            map((data: any) => ({
                updatedBy: data[0].data.sort(sortByLabel),
                areas: data[1].data.sort(sortByLabel),
                bestPractices: data[2].data.sort(sortByLabel),
                severities: data[3].data,
                namespaces: data[4].data.sort(sortByLabel),
                auditApplication: data[4].data.sort(sortByLabel),
                CInamespaces: data[4].data.sort(sortByLabel),
                cetypes: data[5].sort(sortByLabel),
                sources: data[6].data.sort(sortByLabel),
                auditElement: data[7].data.sort(sortByLabel),
                createdBy: data[8].data.sort(sortByLabel),
                scansFilter: data[9].data
                    .filter((scan) => +scan.value < scanId)
                    .sort(sortByDate),
                typeOfChange: data[10].data.sort(sortByLabel),
                ootbModifications: data[11].data.sort(sortByLabel),
                auditUpdatedBy: data[12].data.sort(sortByLabel),
                cetypesnames: data[13].data.sort(sortByLabel),
                licensesCustomer: data[14].data
                    .map((elem: { label: string; value: string }) => {
                        const newElem = { ...elem };
                        newElem.value = newElem.label;
                        return newElem;
                    })
                    .sort(sortByLabel),
                orgs: data[15].data.sort(sortByLabel),
                cmNamespaces: data[16].data.sort(sortByLabel),
                updatedByUgr: data[17].data.sort(sortByLabel),
                cetypesnamesUgr: data[18].data
                    .map((elem: { label: string; value: string }) => {
                        const newElem = { ...elem };
                        newElem.value = newElem.label;
                        return newElem;
                    })
                    .sort(sortByLabel),
                cetypesnamesisootb: data[19].data
                    .map((elem: { label: string; value: string }) => {
                        const newElem = { ...elem };
                        newElem.value = newElem.label;
                        return newElem;
                    })
                    .sort(sortByLabel)
            }))
        );
    }

    getDateFiltersByDate(range, instanceId, serviceId) {
        return this.getDateFilters(range, instanceId, serviceId).pipe(first());
    }

    initialLoad(): Observable<InitialLoadSuccessData> {
        return combineLatest([
            this.userService.getCurrentUserSettings(),
            this.getDashboardContext()
        ]).pipe(
            map(([settings, dashboardContext]) => {
                return {
                    settings,
                    dashboardContext
                };
            })
        );
    }

    getCustomerLicensesWithAddons(customerId: number): Observable<any> {
        const url =
            environment.apiUrl +
            '/v2/customer-license?include=add-on&filter[customer_id]=' +
            customerId;
        return this.http.get(url);
    }

    private getDashboardContext(): Observable<any> {
        const url = `${environment.apiDevEndPoint}api/web/dashboard-context`;
        return this.http.get(url).pipe(
            map((data: any) => parseDashboardContext(data.data)),
            catchError(() =>
                of([
                    {
                        serviceId: SALESFORCE_ID,
                        available: false
                    },
                    {
                        serviceId: OFFICE_ID,
                        available: false
                    },
                    {
                        serviceId: ALL_CLOUDS,
                        available: false
                    },
                    {
                        serviceId: SERVICENOW_ID,
                        available: false
                    }
                ])
            )
        );
    }

    private getDateCeTypes(
        url: string,
        instanceId: number | string,
        providerId: number,
        range: { begin: any; end: any }
    ) {
        return this.http.get(
            `${url}widgets/v1/live-check-ce-types/${
                instanceId !== null ? instanceId : ''
            }` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}` +
                (instanceId === null
                    ? `&providerId=${providerId || SALESFORCE_ID}`
                    : '')
        );
    }

    private getDateUsers(
        url: string,
        instanceId: number | string,
        providerId: number,
        range: { begin: any; end: any }
    ) {
        return this.http.get(
            `${url}widgets/v1/live-check-users/${
                instanceId !== null ? instanceId : ''
            }` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}` +
                (instanceId === null
                    ? `&providerId=${providerId || SALESFORCE_ID}`
                    : '')
        );
    }

    private getDateCeNames(
        url: string,
        instanceId: number | string,
        providerId: number,
        range: { begin: any; end: any }
    ) {
        return this.http.get(
            `${url}widgets/v1/live-check-issues-ce-names-filter/${
                instanceId !== null ? instanceId : ''
            }` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}` +
                (instanceId === null
                    ? `&providerId=${providerId || SALESFORCE_ID}`
                    : '')
        );
    }

    private getDateIssuesCeTypes(
        url: string,
        instanceId: number | string,
        providerId: number,
        range: { begin: any; end: any }
    ) {
        return this.http.get(
            `${url}widgets/v1/live-check-issues-ce-types-filter/${
                instanceId !== null ? instanceId : ''
            }` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}` +
                (instanceId === null
                    ? `&providerId=${providerId || SALESFORCE_ID}`
                    : '')
        );
    }

    private getDateIssuesDevelopers(
        url: string,
        instanceId: number | string,
        providerId: number,
        range: { begin: any; end: any }
    ) {
        return this.http.get(
            `${url}widgets/v1/live-check-issues-developers-filter/${
                instanceId !== null ? instanceId : ''
            }` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}` +
                (instanceId === null
                    ? `&providerId=${providerId || SALESFORCE_ID}`
                    : '')
        );
    }

    private getDateUpdateSetQgStatus(
        url: string,
        instanceId: number | string,
        serviceId: number,
        range: { begin: any; end: any }
    ) {
        if (instanceId === null || +serviceId !== SERVICENOW_ID) return of([]);
        return this.http.get(
            `${url}widgets/v1/update-set-quality-gates/${instanceId}` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}`
        );
    }

    private getDateUpdateSetStatus(
        url: string,
        instanceId: number | string,
        serviceId: number,
        range: { begin: any; end: any }
    ) {
        if (instanceId === null || +serviceId !== SERVICENOW_ID) return of([]);
        return this.http.get(
            `${url}widgets/v1/update-set-status/${instanceId}` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}`
        );
    }

    private getDateUpdateSetReleaseType(
        url: string,
        instanceId: number | string,
        serviceId: number,
        range: { begin: any; end: any }
    ) {
        if (instanceId === null || +serviceId !== SERVICENOW_ID) return of([]);
        return this.http.get(
            `${url}widgets/v1/update-set-release-types/${instanceId}` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}`
        );
    }

    private getDateScansCeTypes(
        url: string,
        instanceId: number | string,
        providerId: number,
        range: { begin: any; end: any }
    ) {
        return this.http.get(
            `${url}widgets/v1/live-check-scans-ce-types/${
                instanceId !== null ? instanceId : ''
            }` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}` +
                (instanceId === null
                    ? `&providerId=${providerId || SALESFORCE_ID}`
                    : '')
        );
    }

    private getDateScansDevelopers(
        url: string,
        instanceId: number | string,
        providerId: number,
        range: { begin: any; end: any }
    ) {
        return this.http.get(
            `${url}widgets/v1/live-check-scans-developers/${
                instanceId !== null ? instanceId : ''
            }` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}` +
                (instanceId === null
                    ? `&providerId=${providerId || SALESFORCE_ID}`
                    : '')
        );
    }

    private getDateScansQualityGatesResults(
        url: string,
        instanceId: number | string,
        providerId: number,
        range: { begin: any; end: any }
    ) {
        return this.http.get(
            `${url}widgets/v1/live-check-scans-quality-gates-results/${
                instanceId !== null ? instanceId : ''
            }` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}` +
                (instanceId === null
                    ? `&providerId=${providerId || SALESFORCE_ID}`
                    : '')
        );
    }

    private getDateFeatureBranchQgStatus(
        url: string,
        instanceId: number | string,
        serviceId: number,
        range: { begin: any; end: any }
    ) {
        if (instanceId === null || +serviceId !== SALESFORCE_ID) return of([]);
        return this.http.get(
            `${url}widgets/v1/feature-branch-scans-quality-gates/${instanceId}` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}`
        );
    }

    private getDateFeatureBranchStatus(
        url: string,
        instanceId: number | string,
        serviceId: number,
        range: { begin: any; end: any }
    ) {
        if (instanceId === null || +serviceId !== SALESFORCE_ID) return of([]);
        return this.http.get(
            `${url}widgets/v1/feature-branch-scans-status/${instanceId}` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}`
        );
    }

    private getDateFeatureBranchReleaseType(
        url: string,
        instanceId: number | string,
        serviceId: number,
        range: { begin: any; end: any }
    ) {
        if (instanceId === null || +serviceId !== SALESFORCE_ID) return of([]);
        return this.http.get(
            `${url}widgets/v1/feature-branch-scans-release-type/${instanceId}` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}`
        );
    }

    private getDateAppInventoryQgStatus(
        url: string,
        instanceId: number | string,
        serviceId: number,
        range: { begin: any; end: any }
    ) {
        if (instanceId === null || +serviceId !== SERVICENOW_ID) return of([]);
        return this.http.get(
            `${url}widgets/v1/application-scans-quality-gates/${instanceId}` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}`
        );
    }

    private getDateAppInventoryStatus(
        url: string,
        instanceId: number | string,
        serviceId: number,
        range: { begin: any; end: any }
    ) {
        if (instanceId === null || +serviceId !== SERVICENOW_ID) return of([]);
        return this.http.get(
            `${url}widgets/v1/application-scans-status/${instanceId}` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}`
        );
    }

    private getDateUpdateSetName(
        url: string,
        instanceId: number | string,
        serviceId: number,
        range: { begin: any; end: any }
    ) {
        if (instanceId === null || +serviceId !== SERVICENOW_ID) return of([]);
        return this.http.get(
            `${url}widgets/v1/update-set-name/${instanceId}` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}`
        );
    }

    private getDateFeatureBranchName(
        url: string,
        instanceId: number | string,
        serviceId: number,
        range: { begin: any; end: any }
    ) {
        if (instanceId === null || +serviceId !== SERVICENOW_ID) return of([]);
        return this.http.get(
            `${url}widgets/v1/feature-branch-scans-name/${instanceId}` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}`
        );
    }

    private getDateTeams(
        url: string,
        instanceId: number | string,
        range: { begin: any; end: any }
    ) {
        return this.http.get(
            `${url}widgets/v1/live-check-scans-teams/${
                instanceId !== null ? instanceId : ''
            }` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}`
        );
    }

    private getDateIssuesTeams(
        url: string,
        instanceId: number | string,
        range: { begin: any; end: any }
    ) {
        return this.http.get(
            `${url}widgets/v1/live-check-issues-teams-filter/${
                instanceId !== null ? instanceId : ''
            }` +
                `?from=${getYYYYMMDDfromDate(range.begin)}` +
                `&to=${getYYYYMMDDfromDate(range.end)}`
        );
    }
}
