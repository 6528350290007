import { Component, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup
} from '@angular/forms';
import {
    dashboardSelector,
    selectedDateRangeSelector,
    showDateSelect
} from '../../../core/state/general/general.selectors';
import { filter, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/core.state';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SatDatepickerInputEvent } from 'saturn-datepicker';
import { GeneralActions } from '../../../core/state/general/general.actions';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';

const DEFAULT_DATES = Object.freeze([
    { label: 'Last week', value: 'W' },
    { label: 'Last month', value: 'M' },
    { label: 'Last quarter', value: 'Q' },
    { label: 'Last six months', value: '6' },
    { label: 'Last year', value: 'Y' }
]);

const ALL_TIMES = Object.freeze({ label: 'All times', value: 'A' });

const DASHBOARDS_WITH_ALL_TIMES_OPTION = [
    'impact-analysis',
    'debt-manager',
    'debt-manager-new'
];

@Component({
    selector: 'qcbi-range-select',
    templateUrl: './range-select.component.html'
})
export class RangeSelectComponent implements OnInit {
    readonly TODAY = new Date();

    selectedDateRange$: any;
    formGroup = new UntypedFormGroup({
        startDate: new UntypedFormControl(null),
        endDate: new UntypedFormControl(null)
    });
    form: UntypedFormGroup;
    showDateSelect$: Observable<boolean>;
    dateOptions$: Observable<{ label: string; value: string }[]>;

    constructor(
        private store: Store<AppState>,
        private formBuilder: UntypedFormBuilder,
        public router: Router
    ) {}

    ngOnInit() {
        this.form = this.formBuilder.group({ dateRangeSelector: undefined });
        this.showDateSelect$ = this.store.select(showDateSelect);
        this.selectedDateRange$ = this.store
            .select(selectedDateRangeSelector)
            .pipe(
                map((value) => ({ ...value })),
                tap(() => {
                    this.form.reset();
                })
            );
        this.dateOptions$ = this.store.select(dashboardSelector).pipe(
            filter((d) => !!d),
            map((d) => d.url),
            map((dashboardUrl) => {
                if (DASHBOARDS_WITH_ALL_TIMES_OPTION.includes(dashboardUrl))
                    return [...DEFAULT_DATES, ALL_TIMES];
                return [...DEFAULT_DATES];
            })
        );
    }

    handleDateChange(e: SatDatepickerInputEvent<Date>) {
        this.store.dispatch(
            GeneralActions.setDashboardDateRange({
                range: e.value || { begin: null, end: null }
            })
        );
    }

    handleDateDropDownChange(e: MatSelectChange) {
        switch (e.value) {
            case 'W':
                this.setLastWeek();
                break;
            case 'M':
                this.setLastMonth();
                break;
            case 'Q':
                this.setLastQuarter();
                break;
            case '6':
                this.setLastSixMonths();
                break;
            case 'Y':
                this.setLastYear();
                break;
            case 'A':
                this.removeDates();
                break;
            default:
                console.error(
                    `wrong date selected. there's no value for ${e.value}`
                );
                break;
        }
    }

    private removeDates() {
        this.store.dispatch(
            GeneralActions.setDashboardDateRange({
                range: { begin: null, end: null }
            })
        );
    }

    private setLastWeek() {
        const begin = new Date();
        begin.setDate(begin.getDate() - 7);
        const end = new Date();
        const range = { begin, end };
        this.store.dispatch(GeneralActions.setDashboardDateRange({ range }));
    }

    private setLastMonth() {
        this.setCurrentDateSubtractingNMonths(1);
    }

    private setLastQuarter() {
        this.setCurrentDateSubtractingNMonths(3);
    }

    private setLastSixMonths() {
        this.setCurrentDateSubtractingNMonths(6);
    }

    private setLastYear() {
        this.setCurrentDateSubtractingNMonths(12);
    }

    private setCurrentDateSubtractingNMonths(n: number) {
        const begin = new Date();
        begin.setMonth(begin.getMonth() - n);
        const end = new Date();
        const range = { begin, end };
        this.store.dispatch(GeneralActions.setDashboardDateRange({ range }));
    }
}
