import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopUpIssuesComponent } from './pop-up-issues.component';
import { TableModule } from '../../components/tables/table.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
    imports: [CommonModule, TableModule, MatButtonModule, MatDialogModule],
    declarations: [PopUpIssuesComponent],
    exports: [PopUpIssuesComponent]
})
export class PopUpIssuesModule {}
