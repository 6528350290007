import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopUpSelectDialogComponent } from './pop-up-select-dialog/pop-up-select-dialog.component';
import { PopUpSelectComponent } from './pop-up-select/pop-up-select.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ServiceDependentLabelsModule } from '../../pipes/service-dependent-labels/service-dependent-labels.module';
import { A11yModule } from '@angular/cdk/a11y';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ScrollDirective } from './pop-up-select-dialog/scroll.directive';
import { SelectOptionComponent } from './pop-up-select-dialog/select-option.component';

@NgModule({
    imports: [
        CommonModule,
        MatSelectModule,
        MatCheckboxModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        MatDialogModule,
        MatButtonModule,
        ServiceDependentLabelsModule,
        A11yModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        FormsModule
    ],
    declarations: [
        PopUpSelectDialogComponent,
        PopUpSelectComponent,
        ScrollDirective,
        SelectOptionComponent
    ],
    exports: [PopUpSelectDialogComponent, PopUpSelectComponent]
})
export class MatPopUpSelectModule {}
