import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QualityGateStatusComponent } from './quality-gate-status.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';

@NgModule({
    imports: [CommonModule, MatChipsModule, MatTooltipModule],
    declarations: [QualityGateStatusComponent],
    exports: [QualityGateStatusComponent]
})
export class QualityGateStatusModule {}
