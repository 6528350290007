<div>
	<div class="pl-4 pt-4 text-xl font-bold">{{data.title}}</div>
	<div class="px-4 pb-4 pt-2">
		<p>{{data.content}}</p>
		<div class="actions">
            <button
                mat-raised-button
                color="primary"
                [loading]="loading$ | async"
                (click)="accept()"
            >
                {{ data.actionText || 'Ok' }}
            </button>
			<button
				mat-stroked-button
				color="primary"
				[disabled]="loading$ | async"
				(click)="closeDialog()"
			>
				Cancel
			</button>
		</div>
	</div>
</div>