import { Injectable } from '@angular/core';
import {
    ActionableService,
    ActionDispatchedEvent
} from '../../../../../tables/services/action.models';
import { ChangeSmartTagDialogComponent } from '../../../../../../shared/change-smart-tag-dialog/change-smart-tag-dialog.component';
import { stateSelectors as dmNewSelectors } from '../../../../../../core/state/widgets/large/debt-manager-new/dm-new.selectors';
import { first } from 'rxjs/operators';
import { bulkActions } from '../../../../../../core/state/widgets/large/debt-manager-new/bulk.actions';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/core.state';

@Injectable({
    providedIn: 'root'
})
export class SmartTagActionService implements ActionableService {
    constructor(private dialog: MatDialog, private store: Store<AppState>) {}

    readonly actionId = 'smart-tag';

    executeAction(event: ActionDispatchedEvent): void {
        const dialogRef = this.dialog.open(ChangeSmartTagDialogComponent, {
            width: '256px'
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (res)
                this.store.dispatch(
                    bulkActions.bulkSmartTag({
                        ids: event.selectedItems.map((el) => +el['Element Id']),
                        allSelected: event.allSelected,
                        smartTag: res
                    })
                );
        });
    }
}
