import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'qcbi-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<TermsComponent>) {}

    ngOnInit() {}

    close(): void {
        this.dialogRef.close();
    }
}
