import { Pipe, PipeTransform } from '@angular/core';
import { ChartData } from '../models/ChartData';
import { ApexXAxis } from 'ng-apexcharts';
import { AppState } from '../core/core.state';
import { Store } from '@ngrx/store';
import { selectCurrentUser } from '../core/auth-lib';
import { first } from 'rxjs/operators';

@Pipe({ name: 'lineXAxis' })
export class LineXAxisPipe implements PipeTransform {
    timeFormat;

    constructor(private store: Store<AppState>) {
        this.store
            .select(selectCurrentUser)
            .pipe(first())
            .subscribe(user => {
                this.timeFormat = user.timeFormat;
            });
    }

    transform(
        data: ChartData[],
        formatDate?: boolean,
        formatCategories?: boolean,
        formatDefault?: number | string | null
    ): ApexXAxis {
        if (!this.isValid(data)) return { categories: [] };
        return {
            type: 'category',
            categories: data[0].data.map(obj => {
                const newObj = { ...obj };
                if (
                    formatDefault !== null &&
                    formatDefault !== undefined &&
                    +formatDefault === 2 &&
                    newObj.label === 'N/A'
                ) {
                    newObj.label = 'Default';
                }
                return formatDate
                    ? this.getFormatedDate(newObj.label)
                    : formatCategories
                    ? this.getSplittedLabels(newObj.label)
                    : newObj.label;
            })
        };
    }

    getFormatedDate(date): string {
        if (
            this.timeFormat === undefined ||
            this.timeFormat === null ||
            this.timeFormat === 'EU'
        )
            return (
                date.substr(8, 2) +
                '/' +
                date.substr(5, 2) +
                '/' +
                date.substr(0, 4) +
                ' ' +
                date.substr(11, 8)
            );
        return (
            date.substr(5, 2) +
            '/' +
            date.substr(8, 2) +
            '/' +
            date.substr(0, 4) +
            ' ' +
            date.substr(11, 8)
        );
    }

    getSplittedLabels(label) {
        const array = label.trim().split(' ');
        const result = [];

        const MAX = 21;
        const MAXLINES = 4;
        let line = '';

        array.forEach((element, index) => {
            if (line.length + element.length + 1 <= MAX) {
                if (index === 0) {
                    line = element;
                } else {
                    line = line + ' ' + element;
                }
            } else if (result.length < MAXLINES - 1) {
                result.push(line);
                line = element;
            } else if (result.length === MAXLINES - 1) {
                if (line.length <= MAX - 3) {
                    line += '...';
                }
                result.push(line);
                line = element;
            }
        });

        if (result.length < MAXLINES) {
            result.push(line);
        }
        return result;
    }

    isValid(data: ChartData[]): boolean {
        if (data === undefined || data === null) return false;
        return data.length !== 0;
    }
}
