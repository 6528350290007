import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfDownloadDialogComponent } from './pdf-download-dialog/pdf-download-dialog.component';
import { PdfDownloadComponent } from './pdf-download.component';
import { FormsModule } from '@angular/forms';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [PdfDownloadDialogComponent, PdfDownloadComponent],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatTooltipModule,
        FormsModule,
        MatFormFieldModule,
        MatRadioModule,
        MatIconModule
    ],
    exports: [PdfDownloadComponent]
})
export class PdfDownloadModule {}
