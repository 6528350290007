import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexMarkers,
    ApexPlotOptions,
    ApexStroke,
    ApexXAxis,
    ApexYAxis
} from 'ng-apexcharts';
import { Chart } from '../../abstracts/chart.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'qcbi-line-chart',
    styleUrls: ['./charts.scss'],
    template: `
        <div class="chart-container">
            <qcbi-chart-loader *ngIf="loadingChart"></qcbi-chart-loader>
            <apx-chart
                [style]="{ opacity: loadingChart ? '.2' : '1' }"
                #chart
                [annotations]="chartAnnotations"
                [autoUpdateSeries]="true"
                [chart]="chartConfig"
                [dataLabels]="dataLabels"
                [legend]="legend"
                [noData]="noData"
                [markers]="_markers"
                [plotOptions]="plotOptions"
                [responsive]="responsive"
                [series]="series"
                [states]="states"
                [stroke]="stroke"
                [theme]="theme"
                [title]="title"
                [tooltip]="tooltip"
                [xaxis]="_xaxis"
                [yaxis]="yaxis"
            >
            </apx-chart>
        </div>
    `
})
export class LineChartComponent extends Chart {
    sizes = {
        small: {
            height: 150,
            width: '100%',
            responsive: []
        },
        medium: {
            height: 500,
            width: '100%',
            responsive: []
        },
        large: {
            height: 600,
            width: '100%',
            responsive: []
        }
    };

    chartConfig: ApexChart = {
        height: 600,
        type: 'line',
        toolbar: {
            show: true,
            offsetY: 50,
            offsetX: -15,
            tools: {
                pan: false
            }
        },
        sparkline: {
            enabled: false
        },
        fontFamily: 'Rubik, sans-serif',
        width: '100%',
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: false
            }
        }
    };

    plotOptions: ApexPlotOptions = {};

    stroke: ApexStroke = {
        width: 3,
        lineCap: 'round'
    };
    @Input() series: ApexAxisChartSeries;
    @Input() yaxis: ApexYAxis | ApexYAxis[];

    constructor(
        public datepipe: DatePipe,
        public numberpipe: DecimalPipe,
        public store: Store<AppState>
    ) {
        super(datepipe, store, numberpipe);
    }

    _markers: ApexMarkers = {
        size: [3],
        hover: {
            size: 6
        }
    };

    @Input() set markers(value: ApexMarkers) {
        if (!value) return;
        this._markers = {
            ...value
        };
    }

    _xaxis: ApexXAxis;

    @Input() set xaxis(value: ApexXAxis) {
        if (!value) return;
        this._xaxis = {
            ...value,
            type: 'datetime',
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: false,
                minHeight: 80,
                showDuplicates: true,
                trim: true,
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM',
                    day: 'dd MMM',
                    hour: 'HH:mm dd',
                    minute: 'HH:mm'
                }
            },
            tickPlacement: 'on'
        };
    }

    getLabelText(value, self: this): string {
        const date = new Date(value);
        if (!isNaN(date.getMonth())) {
            const padLeft = n => ('00' + n).slice(-2);
            value = `${padLeft(date.getDate())}/${padLeft(
                date.getMonth() + 1
            )} ${padLeft(date.getHours())}:${padLeft(date.getMinutes())}`;
        }
        return value;
    }
}
