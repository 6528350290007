<div class="flex items-center -mx-2"
     *ngIf="(showDateSelect$ | async) && router.url !== '/notifications'">
    <div class="flex px-4 text-xs">
        <div [formGroup]="form">
            <mat-form-field style="width: 136px">
                <mat-select
                    placeholder="select a range"
                    (selectionChange)="handleDateDropDownChange($event)"
                    formControlName="dateRangeSelector"
                    aria-label="date options"
                >
                    <mat-option
                        *ngFor="let option of dateOptions$ | async"
                        [value]="option.value"
                    >
                        {{ option.label | titlecase }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="flex px-4 text-xs">
        <mat-form-field>
            <input matInput
                   placeholder="Choose a date"
                   [satDatepicker]="picker3"
                   [value]="selectedDateRange$ | async"
                   (dateChange)="handleDateChange($event)"
                   [max]="TODAY"
                   aria-label="date select"
            >
            <sat-datepicker #picker3 [rangeMode]="true">
            </sat-datepicker>
            <sat-datepicker-toggle matSuffix [for]="picker3"></sat-datepicker-toggle>
        </mat-form-field>
    </div>
</div>