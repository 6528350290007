import {
    ApexAnnotations,
    ApexChart,
    ApexDataLabels,
    ApexLegend,
    ApexNoData,
    ApexPlotOptions,
    ApexResponsive,
    ApexStates,
    ApexStroke,
    ApexTheme,
    ApexTitleSubtitle,
    ApexTooltip
} from 'ng-apexcharts';
import {
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../core/core.state';
import { selectCurrentUser } from '../core/auth-lib';
import { first } from 'rxjs/operators';

export interface ChartSizes {
    small: {
        height: number | 'auto' | string;
        width: number | 'auto' | string;
        responsive: ApexResponsive[];
    };
    medium: {
        height: number | 'auto' | string;
        width: number | 'auto' | string;
        responsive: ApexResponsive[];
    };
    large: {
        height: number | 'auto' | string;
        width: number | 'auto' | string;
        responsive: ApexResponsive[];
    };
}

const NO_DATA_TEXT = 'No data available';
const LOADING_DATA_TEXT = '';

@Directive() // tslint:disable-next-line:directive-class-suffix
export abstract class Chart implements OnInit, OnDestroy {
    public abstract chartConfig: ApexChart;
    public abstract sizes: ChartSizes;
    public abstract stroke: ApexStroke;
    public abstract plotOptions: ApexPlotOptions;

    public responsive: ApexResponsive[];

    timeFormat;
    @Input() showTopFive = true;
    @Input() logarithmic = false;
    @Input() data: any;
    @Input() extraInfo: object;
    @Input() measures: string[] = [];
    @Input() colors: string[];
    @Input() mainMeasure: string;
    @Input() percentageValue = false;
    @Input() extraValue;
    @Input() showMinutes = true;
    public chartAnnotations: ApexAnnotations = {
        yaxis: [],
        xaxis: [],
        points: []
    };
    @Output() markerClick = new EventEmitter();
    @Output() dataClick = new EventEmitter();
    loadingChart = false;
    public title: ApexTitleSubtitle = {
        text: '',
        align: 'center',
        offsetX: 0,
        offsetY: 0,
        floating: true,
        style: {
            fontSize: '1.425em',
            fontWeight: 'normal',
            fontFamily: 'Rubik, sans-serif'
        },
        margin: 75
    };
    public subtitle: ApexTitleSubtitle = {
        text: '',
        align: 'center',
        offsetX: 0,
        offsetY: 60,
        floating: true,
        style: {
            fontSize: '1em',
            fontWeight: 'normal',
            fontFamily: 'Rubik, sans-serif'
        }
    };
    public theme: ApexTheme = {
        monochrome: {
            enabled: true,
            color: '#4368B0',
            shadeIntensity: 1
        }
    };
    public legend: ApexLegend = {
        containerMargin: {
            top: 0
        }, // height: 100,
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: '10px',
        onItemHover: {
            highlightDataSeries: false
        },
        onItemClick: {
            toggleDataSeries: true
        },
        markers: {
            radius: 4,
            height: 10,
            width: 10
        },
        itemMargin: {
            horizontal: 2,
            vertical: 2
        }
    };
    public tooltip: ApexTooltip = {
        followCursor: false,
        custom: event => this.renderTooltip(event, this)
    };
    public noData: ApexNoData = {
        text: NO_DATA_TEXT,
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
            fontSize: '14px',
            fontFamily: 'Rubik, sans-serif'
        }
    };
    public dataLabels: ApexDataLabels = {
        style: {
            fontSize: '16px',
            colors: ['#989aab'],
            fontWeight: 'normal',
            fontFamily: 'Rubik, sans-serif'
        },
        offsetX: 20,
        offsetY: 20,
        textAnchor: 'end',
        dropShadow: {
            enabled: false
        }
    };
    public states: ApexStates = {
        normal: {
            filter: {
                type: 'none',
                value: 0
            }
        },
        hover: {
            filter: {
                type: 'darken',
                value: 0.25
            }
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'darken',
                value: 0.25
            }
        }
    };
    public toolTipKeyWhitelist = [
        { key: 'impact', label: 'Impact' },
        { key: 'action', label: 'Action' }
    ];

    constructor(
        public datepipe: DatePipe,
        public store: Store<AppState>,
        public numberpipe?: DecimalPipe,
        public translateService?: TranslateService
    ) {}

    @Input() set animation(value: boolean) {
        this.chartConfig.animations.enabled = value;
    }

    @Input() set loading(value: boolean) {
        this.noData.text = value ? LOADING_DATA_TEXT : NO_DATA_TEXT;
        this.loadingChart = value;
    }

    @Input() set toolbar(value: boolean) {
        this.chartConfig.toolbar.show = value;
        this.chartConfig.toolbar.tools.download = value;
    }

    @Input() set height(value: number) {
        this.chartConfig.height = value;
    }

    @Input() set size(value: 'small' | 'medium' | 'large') {
        if (this.sizes[value] !== undefined) {
            this.chartConfig.height =
                this.chartConfig.height || this.sizes[value].height;
            this.chartConfig.width = this.sizes[value].width;
            this.responsive = this.sizes[value].responsive;
        }
    }

    @Input() set text(value: string) {
        this.title.text = value;
        this.title.text.length <= 0
            ? (this.title.margin = 0)
            : (this.title.margin = 75);
    }

    @Input() set subtitleText(value: string) {
        this.subtitle.text = value;
    }

    @Input() set scanIndex(value: any) {
        if (value !== null && value.label != null) {
            this.chartAnnotations.xaxis.push({
                x: new Date(value.label).getTime(),
                borderColor: '#000',
                fillColor: '#FEB019',
                label: {
                    text: 'Scan selected'
                }
            });
        }
    }

    @Input() set scanPointIndex(value: any) {
        if (value !== null && value.label != null && value.value != null) {
            this.chartAnnotations.points.push({
                x: new Date(value.label).getTime(),
                y: value.value,
                marker: {
                    size: 2,
                    fillColor: '#4368B0',
                    strokeColor: '#4368B0'
                }
            });
        }
    }

    renderTooltip(
        { series, seriesIndex, dataPointIndex, w },
        self: this
    ): string {
        const [isMultiple, seriesNames, labels, colors, isStacked] = [
            Array.isArray(series[0]),
            w.globals.seriesNames,
            w.globals.labels,
            w.globals.colors,
            w.config.chart.stacked
        ];
        if (isMultiple) {
            const sortedData = self.data !== undefined ? [...self.data] : [];
            if (!isStacked) {
                sortedData.sort(
                    (a, b) =>
                        +b.data[dataPointIndex].value -
                        +a.data[dataPointIndex].value
                );
            }
            return (
                `<div class="qcchart-tooltip">` +
                series.reduce((result, serie: any[], index: number) => {
                    if (
                        self.data !== undefined &&
                        (index < 5 || !this.showTopFive)
                    ) {
                        const item = sortedData[index].data[dataPointIndex];
                        const unsortedIndex = self.data.findIndex(
                            it => it.name === sortedData[index].name
                        );
                        if (item !== undefined) {
                            result += self.renderSerieTooltip(
                                unsortedIndex,
                                item,
                                colors,
                                seriesNames,
                                self.measures.length > 0
                                    ? self.measures[unsortedIndex]
                                    : '',
                                false,
                                self.percentageValue,
                                self.extraValue,
                                seriesIndex === unsortedIndex
                            );
                        }
                    }
                    return result;
                }, ``) +
                self.renderSharedInfo(
                    self.data,
                    self.toolTipKeyWhitelist,
                    seriesIndex,
                    dataPointIndex,
                    self.mainMeasure
                ) +
                (series.length > 5 && this.showTopFive
                    ? `<div class="series-container p-2 m-0" style="border-left: 10px solid #e0e6ea">` +
                      `<div class="info-container row">` +
                      `<div class="col-auto"><p class="m-0">(showing top 5)</p></div>` +
                      `</div></div>`
                    : '') +
                `${
                    self.extraInfo
                        ? this.renderExtraInfo(
                              self.extraInfo,
                              self.translateService
                          )
                        : ''
                }` +
                `</div>`
            );
        } else {
            return self.data === undefined
                ? ''
                : `
                <div class="qcchart-tooltip">
                    ${self.renderSerieTooltip(
                        seriesIndex,
                        self.data[0].data[seriesIndex],
                        colors,
                        labels,
                        self.measures.length > 0 ? self.measures[0] : '',
                        true,
                        self.percentageValue,
                        self.extraValue
                    )}
                    ${self.renderValueExtraInfo(
                        seriesIndex,
                        self.data[0].data[seriesIndex]
                    )}
                    ${
                        self.extraInfo
                            ? this.renderExtraInfo(
                                  self.extraInfo,
                                  self.translateService
                              )
                            : ''
                    }
                </div>`;
        }
    }

    renderSerieTooltip(
        index,
        item,
        colors,
        seriesNames,
        measure,
        withLabel = false,
        percentageValue = false,
        extraValue,
        bold = false
    ) {
        return (
            `<div class="series-container p-2 m-0" style="border-left: 10px solid ${colors[index]}">` +
            `<h3 class="m-0">` +
            (bold
                ? `<b>${seriesNames[index]}</b>`
                : `${seriesNames[index]}</ng-container>`) +
            `</h3>` +
            `<div class="info-container row">` +
            `<div class="col-auto">` +
            (!withLabel
                ? ''
                : seriesNames[index] !== item.label
                ? `<div class="text-xs text-secondary-500">${item.label}</div>`
                : ``) +
            `<p class="m-0">${this.numberpipe.transform(
                this.logarithmic && item.value === 0.1 ? 0 : item.value
            )} ${measure}` +
            (percentageValue ? '%' : '') +
            (extraValue
                ? ` (${this.numberpipe.transform(item[extraValue])})`
                : '') +
            `</p></div></div></div>`
        );
    }

    renderSharedInfo(
        data,
        infoList,
        serieIndex: number,
        dataIndex: number,
        mainMeasure = ''
    ) {
        const target = data[serieIndex].data[dataIndex];
        const sharedKeys = infoList.reduce((result, item) => {
            if (target[item.key] !== undefined) {
                result.push(item);
            }
            return result;
        }, []);
        let mainValue = target.label;
        const date = new Date(Date.parse(target.label));
        if (!/^\d{4}-\d{2}$/.test(target.label) && !isNaN(date.getMonth())) {
            const dateOffset = date.getTimezoneOffset() * 60000;
            const zuluDate = new Date(date.getTime() + dateOffset);
            const padLeft = n => ('00' + n).slice(-2);
            mainValue = `${
                this.timeFormat === undefined ||
                this.timeFormat === null ||
                this.timeFormat === 'EU'
                    ? padLeft(zuluDate.getDate())
                    : padLeft(zuluDate.getMonth() + 1)
            }/${
                this.timeFormat === undefined ||
                this.timeFormat === null ||
                this.timeFormat === 'EU'
                    ? padLeft(zuluDate.getMonth() + 1)
                    : padLeft(zuluDate.getDate())
            }/${zuluDate.getFullYear()} ${
                this.showMinutes
                    ? padLeft(zuluDate.getHours()) +
                      ':' +
                      padLeft(zuluDate.getMinutes())
                    : ''
            }`;
        }
        return (
            `<div class="series-container p-2 m-0" style="border-left: 10px solid #e0e6ea">` +
            `<div class="info-container row">` +
            `<div class="col-auto"><div class="text-xs text-secondary-500">${mainMeasure}</div><p class="m-0">${mainValue}</p></div>` +
            (sharedKeys.length === 0
                ? ''
                : sharedKeys.reduce((result, item) => {
                      result += `<div class="col-auto"><div class="text-xs text-secondary-500">${
                          item.label
                      }</div><p class="m-0">${target[item.key]}</p></div>`;
                      return result;
                  }, ``)) +
            `</div></div>`
        );
    }

    renderValueExtraInfo(index, item) {
        if (!item.extra) return '';
        return (
            `<div class="series-container p-2 m-0" style="border-left: 10px solid #e0e6ea">` +
            `<div class="info-container row">` +
            `<div class="col-auto">` +
            '<div class="text-xs text-secondary-500">Information</div>' +
            `<p class="m-0">${item.extra}</p>` +
            '</div>' +
            '</div>' +
            '</div>'
        );
    }

    renderExtraInfo(extraInfo: object, translateService: TranslateService) {
        const keys = Object.keys(extraInfo);
        return `
        <div class="series-container p-2 m-0" style="border-left: 10px solid #e0e6ea">
            <div class="info-container row">
              ${keys.map(
                  key => `
                    <div class="col-auto">
                        <div class="text-xs text-secondary-500">${
                            key ? translateService.instant(key) : ''
                        }</div>
                        <p class="m-0">${
                            extraInfo[key]
                                ? translateService.instant(extraInfo[key])
                                : ''
                        }</p>
                    </div>
                  `
              )}
            </div>
        </div>
        `;
    }

    ngOnInit() {
        this.store
            .select(selectCurrentUser)
            .pipe(first())
            .subscribe(user => {
                this.timeFormat = user.timeFormat;
            });
    }

    ngOnDestroy() {}
}
