import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardDialogComponent } from './dashboard-dialog.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
    imports: [CommonModule, MatButtonModule],
    declarations: [DashboardDialogComponent],
    exports: [DashboardDialogComponent]
})
export class DashboardDialogModule {}
